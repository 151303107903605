<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-13 13:08:26
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-03-13 21:26:01
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\taxRiskControl\reportAnalyse\Table.vue
-->
<template>
  <div>
    <div class="options">
      <a-form layout="inline" :form="form">
        <a-form-item label="申报时期">
          <a-button-group style="margin-left: 20px">
            <a-button>年度</a-button>
            <a-button type="primary">季度</a-button>
            <a-button>月度</a-button>
          </a-button-group>
        </a-form-item>
        <a-form-item label="时间" style="margin-left: 20px">
          <a-select
            placeholder="请选择年份"
            style="width: 200px; margin-left: 20px"
          >
            <a-select-option value="2022"> 2022 </a-select-option>
            <a-select-option value="2021"> 2021 </a-select-option>
            <a-select-option value="2019"> 2019 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item style="margin-left: 20px">
          <a-button type="primary">内部审核表</a-button>
        </a-form-item>
        <a-form-item style="margin-left: 5px">
          <a-button>上传审核表</a-button>
        </a-form-item>
      </a-form>
      <a-button type="primary" style="border-radius: 15px">立即对比</a-button>
    </div>
    <div class="content">
      <a-row :gutter="20">
        <a-col :span="8">
          <div class="statistic">
            <h1>指标统计</h1>
            <div class="count">
              <div class="index">
                <div class="left">
                  <img :src="require('@/assets/img/y1.png')" alt="" />
                  <div class="name">指标</div>
                </div>
                <div class="right">
                  <span>882</span>
                </div>
              </div>
              <div class="index">
                <div class="left">
                  <img :src="require('@/assets/img/y2.png')" alt="" />
                  <div class="name">异常指标</div>
                </div>
                <div class="right">
                  <span>181</span>
                </div>
              </div>
              <div class="index">
                <div class="left">
                  <img :src="require('@/assets/img/y3.png')" alt="" />
                  <div class="name">正常指标</div>
                </div>
                <div class="right">
                  <span>701</span>
                </div>
              </div>
            </div>
            <h1>指标占比</h1>
            <v-chart :options="options" height="350px" />
            <div class="tip">
              说明：本次检测总计有<span
                style="font-size: 20px; color: #383838; margin: 0 5px"
                >882</span
              >个指标，结果显示<span
                style="font-size: 20px; color: #03bb6b; margin: 0 5px"
                >701</span
              >个指标正常，风险指标<span
                style="font-size: 20px; color: #ff941a; margin: 0 5px"
                >181</span
              >项。
            </div>
          </div>
        </a-col>
        <a-col :span="16">
          <div class="table">
            <div class="card-container">
              <a-tabs type="card">
                <a-tab-pane key="1" tab="异常指标">
                  <a-table
                    :columns="columns"
                    :data-source="data"
                    :pagination="{ pageSize: 15 }"
                  >
                  </a-table>
                </a-tab-pane>
                <a-tab-pane key="2" tab="正常指标">
                  <a-table
                    :columns="columns"
                    :data-source="data"
                    :pagination="{ pageSize: 15 }"
                  >
                  </a-table>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "序号",
    width: "100px",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "指标名称",
    width: "300px",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "风险描述",
    dataIndex: "desc",
    key: "desc",
    align: "center",
  },
];
export default {
  name: "",
  data() {
    return {
      form: {},
      data: [
        {
          id: 1,
          name: "职工薪酬账载金额",
          desc: "A104000《期间费用明细表》销售费用和管理费用“职工薪酬”之和不等于A105000《纳税调整项目明细表》“职工薪酬账载金额”，请核查在销售费用或营业费用、管理费用、研发支出、制造费用、生产成本、在建工程、应付职工薪酬等科目中“职工薪酬”的本年发生或计提额。",
        },
        {
          id: 2,
          name: "职工薪酬账载金额",
          desc: "A104000《期间费用明细表》销售费用和管理费用“职工薪酬”之和不等于A105000《纳税调整项目明细表》“职工薪酬账载金额”，请核查在销售费用或营业费用、管理费用、研发支出、制造费用、生产成本、在建工程、应付职工薪酬等科目中“职工薪酬”的本年发生或计提额。",
        },
        {
          id: 3,
          name: "职工薪酬账载金额",
          desc: "A104000《期间费用明细表》销售费用和管理费用“职工薪酬”之和不等于A105000《纳税调整项目明细表》“职工薪酬账载金额”，请核查在销售费用或营业费用、管理费用、研发支出、制造费用、生产成本、在建工程、应付职工薪酬等科目中“职工薪酬”的本年发生或计提额。",
        },
        {
          id: 4,
          name: "职工薪酬账载金额",
          desc: "A104000《期间费用明细表》销售费用和管理费用“职工薪酬”之和不等于A105000《纳税调整项目明细表》“职工薪酬账载金额”，请核查在销售费用或营业费用、管理费用、研发支出、制造费用、生产成本、在建工程、应付职工薪酬等科目中“职工薪酬”的本年发生或计提额。",
        },
        {
          id: 5,
          name: "职工薪酬账载金额",
          desc: "A104000《期间费用明细表》销售费用和管理费用“职工薪酬”之和不等于A105000《纳税调整项目明细表》“职工薪酬账载金额”，请核查在销售费用或营业费用、管理费用、研发支出、制造费用、生产成本、在建工程、应付职工薪酬等科目中“职工薪酬”的本年发生或计提额。",
        },
        {
          id: 6,
          name: "职工薪酬账载金额",
          desc: "A104000《期间费用明细表》销售费用和管理费用“职工薪酬”之和不等于A105000《纳税调整项目明细表》“职工薪酬账载金额”，请核查在销售费用或营业费用、管理费用、研发支出、制造费用、生产成本、在建工程、应付职工薪酬等科目中“职工薪酬”的本年发生或计提额。",
        },
        {
          id: 7,
          name: "职工薪酬账载金额",
          desc: "A104000《期间费用明细表》销售费用和管理费用“职工薪酬”之和不等于A105000《纳税调整项目明细表》“职工薪酬账载金额”，请核查在销售费用或营业费用、管理费用、研发支出、制造费用、生产成本、在建工程、应付职工薪酬等科目中“职工薪酬”的本年发生或计提额。",
        },
      ],
      columns,
      options: {
        tooltip: {
          trigger: "item",
        },
        color: ["#ff941a", "#03bb6b"],
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 181, name: "异常指标" },
              { value: 701, name: "正常指标" },
            ],
          },
        ],
      },
    };
  },
  methods: {
    statusFormatter(status) {
      if (status == 1) {
        return "已上传";
      } else {
        return "未上传";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}
.statistic {
  background: #fff;
  padding: 20px;
  .count {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    margin: 40px 0;
    .index {
      display: flex;
      justify-content: flex-start;
      .left {
        .name {
          margin-top: 10px;
        }
      }
      .right {
        margin-left: 20px;
        span {
          font-size: 40px;
          font-weight: 900;
          font-family: PingFangSC-Semibold;
        }
      }
    }
  }
  .tip {
    text-align: center;
    font-size: 16px;
  }
}
.table {
  .card-container {
    background: #f0f2f5;
    overflow: hidden;
  }
  /deep/ .card-container > .ant-tabs-card > .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
  }
  /deep/
    .card-container
    > .ant-tabs-card
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }
  /deep/ .card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }
  /deep/ .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  /deep/ .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  }
}
</style>
