<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-11 23:04:07
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-30 19:59:17
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\taxRiskControl\dataCollect\Index.vue
-->
<template>
  <div class="container">
    <div class="header">
      <a-row :gutter="20">
        <a-col :span="8">
          <div class="instruction">
            <div class="left">
              <img :src="require('@/assets/img/p2.png')" alt="" />
              上传须知
            </div>
            <div class="right">
              <img
                :src="
                  collapsed
                    ? require('@/assets/img/v2.png')
                    : require('@/assets/img/s1.png')
                "
                alt=""
                @click="changeStatus"
              />
            </div>
          </div>
          <div class="instruction-info" v-if="!collapsed">
            <ol>
              <li>
                1.
                按年查询时，需上传连续2个年度的企业所得税年度纳税申报表、两个年度第12月增值税申报表、两个年度的财务报表。
              </li>
              <li>
                2.
                按季查询时，需上传连续2个季度的企业所得税月（季）度预缴纳税申报表、两个季度末增值税纳税申报表、两个季度末财务报表。
              </li>
              <li>
                3. 按月查询时，需上传连续2个月的增值税纳税申报表和财务报表。
              </li>
            </ol>
            <div class="btn">
              <a-button type="primary" @click="changeStatus"
                >已阅 {{ showTime ? time + " S" : "" }}</a-button
              >
            </div>
          </div>
        </a-col>
        <a-col :span="16">
          <div class="riskAnalyse">
            <img :src="require('@/assets/img/banner0.png')" alt="" />
            <div class="lastTime">
              <span class="text"
                >距离上次检测时间<span class="num">{{
                  lastExm == -1 ? 0 : lastExm
                }}</span
                >天</span
              >
            </div>
            <a-button
              class="analyse"
              @click="$router.push('/taxRiskControl/riskAnalyse')"
              >立即检测</a-button
            >
          </div>
          <Table v-if="!collapsed" />
        </a-col>
      </a-row>
    </div>
    <Table v-if="collapsed" />
  </div>
</template>
<script>
import Table from "./Table.vue";
import { getLstExm } from "@/api/template";
export default {
  name: "",
  components: { Table },
  data() {
    return {
      form: {},
      collapsed: false,
      showTime: true,
      timer: {},
      time: 5,
      lastExm: "",
    };
  },
  created() {
    this.getLstExm();
    this.timer = setInterval(() => {
      this.time--;
      if (this.time == 0) {
        this.collapsed = true;
        this.showTime = false;
        clearInterval(this.timer);
      }
    }, 1000);
  },
  methods: {
    changeStatus() {
      clearInterval(this.timer);
      this.collapsed = !this.collapsed;
      this.showTime = false;
    },
    async getLstExm() {
      let res = await getLstExm();
      if (res.status == 200) {
        this.lastExm = res.data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-tag {
  margin: 0;
}
.container {
  .header {
    .instruction,
    .riskAnalyse {
      height: 100px;
      background: #272d4b;
    }
    .instruction {
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      padding: 20px;
      font-size: 24px;
      font-weight: 900;
      color: #fff;
      .left {
        img {
          position: relative;
          top: -5px;
          margin-right: 10px;
        }
      }
      .right {
        img {
          cursor: pointer;
        }
      }
    }
    .instruction-info {
      padding: 20px;
      color: #fff;
      background: #272d4b;
      ol {
        li {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 0;
        .ant-btn-primary {
          color: #fff;
          background: #283a6c;
          border: none;
        }
      }
    }
    .riskAnalyse {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 20px;
      background: #1581ff;
      .lastTime {
        font-weight: 900;
        font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
        color: #fff;
        text-shadow: 5px 5px #4e6ef2;
        line-height: 78px;
        .num {
          position: relative;
          top: 5px;
          font-size: 60px;
          margin-right: 10px;
        }
        .text {
          font-size: 40px;
          letter-spacing: 10px;
        }
      }
      .analyse {
        color: #fff;
        background: #3be970;
        border-color: #3be970;
        border-radius: 15px;
      }
    }
  }
}
</style>
