/*
 * @Author: zhangjingqing
 * @Date: 2022-03-31 21:52:35
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-15 00:19:12
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\risk.js
 */
import { getAction, postAction } from "./http/manage";
const URL = "";

// 查询风险规则
const getCalrule = (params) => getAction(URL + "/cal-rule/get-calrule", params);
// 查询表列详情
const getTabCol = (params) => getAction(URL + "/cal-rule/get-tabCol", params);
// 查询风险规则详情
const getRiskdetail = (params) =>
  getAction(URL + "/cal-rule/get-riskdetail", params);
// 查询数据表
const getTabdetail = (params) =>
  postAction(URL + "/cal-rule/get-tabdetail", params);
// 新建风险规则
const createRiskRule = (params) =>
  postAction("/cal-rule/create-riskrule", params);
// 删除风险规则
const delRiskRule = (params) => postAction("/cal-rule/del-riskrule", params);
// 修改风险规则
const updateRiskrule = (params) =>
  postAction("/cal-rule/update-riskrule", params);
// 复制风险规则
const copyRiskrule = (params) => postAction("/cal-rule/copy-riskrule", params);

/*
  检测结果
*/
// 税务-检测概况数据
const getOwData = (params) => postAction(URL + "/risk-report/ow-data", params);
// 税务-税务风险PDF数据
const getPdfData = (params) =>
  postAction(URL + "/risk-report/pdf-data", params);
// 税务-税务风险报告数据
const getReportData = (params) =>
  postAction(URL + "/risk-report/report-data", params);

/*
  立即检测
*/
// 税务-税务风险报告数据
const calData = (params) => postAction(URL + "/risk-cal/cal-data", params);

export {
  getCalrule,
  getTabCol,
  getTabdetail,
  createRiskRule,
  getRiskdetail,
  delRiskRule,
  updateRiskrule,
  copyRiskrule,
  getOwData,
  getPdfData,
  getReportData,
  calData,
};
