<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-11 23:04:14
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-12 20:57:53
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\taxRiskControl\reportAnalyse\Index.vue
-->
<template>
  <div class="container">
    <div class="header">
      <a-row :gutter="20">
        <a-col :span="8">
          <div class="instruction">
            <div class="left">
              <img :src="require('@/assets/img/p2.png')" alt="" />
              申报须知
            </div>
            <div class="right">
              <img
                :src="
                  collapsed
                    ? require('@/assets/img/v2.png')
                    : require('@/assets/img/s1.png')
                "
                alt=""
                @click="changeStatus"
              />
            </div>
          </div>
          <div class="instruction-info" v-if="!collapsed">
            <ol>
              <li>1. 比对分析目前仅支持一般纳税人。</li>
              <li>2. 上传新文件会覆盖已有的文件。</li>
              <li>
                3.
                申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。
              </li>
              <li>
                4.
                申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。
              </li>
              <li>
                5.
                申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。
              </li>
              <li>
                6.
                申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。申报年度的材料，需要提交......申报年度的材料。
              </li>
            </ol>
            <div class="btn">
              <a-button type="primary" @click="changeStatus"
                >已阅 10 S</a-button
              >
            </div>
          </div>
        </a-col>
        <a-col :span="16">
          <div class="riskAnalyse">
            <div class="left">
              <img :src="require('@/assets/img/banner0.png')" alt="" />
            </div>
            <div class="right">
              <a-button class="analyse">立即检测</a-button>
            </div>
          </div>
          <Table v-if="!collapsed" />
        </a-col>
      </a-row>
    </div>
    <Table v-if="collapsed" />
  </div>
</template>
<script>
import Table from "./Table.vue";
export default {
  name: "",
  components: { Table },
  data() {
    return {
      form: {},
      collapsed: true,
    };
  },
  methods: {
    changeStatus() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-tag {
  margin: 0;
}
.container {
  .header {
    .instruction,
    .riskAnalyse {
      height: 100px;
      background: #272d4b;
    }
    .instruction {
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      padding: 20px;
      font-size: 24px;
      font-weight: 900;
      color: #fff;
      .left {
        img {
          position: relative;
          top: -5px;
          margin-right: 10px;
        }
        .anticon {
          font-size: 30px;
          color: #fc5e59;
        }
      }
      .right {
        img {
          cursor: pointer;
        }
      }
    }
    .instruction-info {
      padding: 20px;
      background: #fff;
      ol {
        li {
          margin-bottom: 20px;
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 0;
      }
    }
    .riskAnalyse {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 20px;
      background: #1581ff;
      .analyse {
        color: #fff;
        background: #3be970;
        border-color: #3be970;
        border-radius: 15px;
      }
    }
  }
}
</style>
