/*
 * @Author: zhangjingqing
 * @Date: 2022-03-28 22:04:44
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-10 15:49:29
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\tax.js
 */
import { getAction, postAction } from "./http/manage";
const URL = "";

// excel数据导入
const getexcel = (params, query) =>
  postAction(
    URL +
      "/getexcel/data-import?year=" +
      query.year +
      "&dateType=" +
      query.dateType +
      "&indexStandardType=" +
      query.indexStandardType +
      "&taxpayerNature=" +
      query.taxpayerNature +
      "&excelName=" +
      query.excelName +
      "&dateTime=" +
      query.dateTime +
      "&dateSource=" +
      query.dateSource,
    params
  );

// 数据导入并计算
const getexcelAll = (params, query) =>
  postAction(
    URL +
      "/getexcel/data-impcal?year=" +
      query.year +
      "&dateType=" +
      query.dateType +
      "&dateTime=" +
      query.dateTime +
      "&dateSource=" +
      query.dateSource,
    params
  );

// 税务-exlcel上传信息
const getUploadStatus = (params) =>
  postAction(URL + "/getexcel/excel-upload-status", params);

// 查询可查询计算年份
const getYear = (params) => getAction(URL + "/common/get-year", params);

export { getexcel, getexcelAll, getUploadStatus, getYear };
