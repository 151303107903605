<template>
  <div class="main">
    <a-spin :spinning="flag">
      <a-form
        :form="form"
        class="user-layout-login"
        ref="formLogin"
        id="formLogin"
      >
        <a-tabs
          :activeKey="customActiveKey"
          :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
        >
          <a-tab-pane key="tab1" tab="账号密码登录">
            <a-form-item>
              <a-input
                size="large"
                v-decorator="['email', validatorRules.username]"
                type="text"
                placeholder="请输入帐户名"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input>
            </a-form-item>

            <a-form-item>
              <a-input
                v-decorator="['password', validatorRules.password]"
                size="large"
                type="password"
                autocomplete="false"
                placeholder="密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input>
            </a-form-item>
          </a-tab-pane>
          <!-- <a-tab-pane key="tab2" tab="手机号登录">
          <a-form-item>
            <a-input
              v-decorator="['mobile', validatorRules.mobile]"
              size="large"
              type="text"
              placeholder="手机号"
            >
              <a-icon
                slot="prefix"
                type="mobile"
                :style="{ color: 'rgba(0,0,0,.25)' }"
              />
            </a-input>
          </a-form-item>

          <a-row :gutter="16">
            <a-col class="gutter-row" :span="16">
              <a-form-item>
                <a-input
                  v-decorator="['captcha', validatorRules.captcha]"
                  size="large"
                  type="text"
                  placeholder="请输入验证码"
                >
                  <a-icon
                    slot="prefix"
                    type="mail"
                    :style="{ color: 'rgba(0,0,0,.25)' }"
                  />
                </a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :span="8">
              <a-button
                class="getCaptcha"
                tabindex="-1"
                :disabled="state.smsSendBtn"
                @click.stop.prevent="getCaptcha"
                v-text="
                  (!state.smsSendBtn && '获取验证码') || state.time + ' s'
                "
              ></a-button>
            </a-col>
          </a-row>
        </a-tab-pane> -->
        </a-tabs>

        <a-form-item style="margin-top: 24px">
          <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="login-button"
            :loading="loginBtn"
            @click.stop.prevent="handleSubmit"
            :disabled="loginBtn"
            >确定
          </a-button>
        </a-form-item>
        <a-form-item>
          <!-- <a-checkbox
          v-decorator="[
            'rememberMe',
            { initialValue: true, valuePropName: 'checked' },
          ]"
          >自动登录</a-checkbox
        > -->
          <a style="float: right; margin-right: 10px" @click="handleRegister">
            注册账号
          </a>
        </a-form-item>
      </a-form>
    </a-spin>
    <register-modal ref="registerModal"></register-modal>
    <a-modal
      ref="errorModal"
      title="令牌失效"
      :dialog-style="{ top: '50%', marginTop: '-100px' }"
      v-model="errorShow"
    >
      <template slot="footer">
        <a-button @click="errorShow = false">关闭</a-button>
      </template>
      请从U8系统重新打开！
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RegisterModal from "./RegisterModal.vue";
import { timeFix, getQueryString, decode } from "@/utils/utils";
import { initDynamicRoutes } from "@/router";
import moment from "moment";
export default {
  name: "Login",
  components: { RegisterModal },
  data() {
    return {
      customActiveKey: "tab1",
      loginBtn: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        smsSendBtn: false,
      },
      validatorRules: {
        username: {
          rules: [
            { required: true, message: "请输入用户名!" },
            { validator: this.handleUsernameOrEmail },
          ],
        },
        password: {
          rules: [
            { required: true, message: "请输入密码!", validator: "click" },
          ],
        },
        mobile: { rules: [{ validator: this.validateMobile }] },
        captcha: { rule: [{ required: true, message: "请输入验证码!" }] },
        inputCode: { rules: [{ required: true, message: "请输入验证码!" }] },
      },
      flag: false,
      errorShow: false,
    };
  },
  mounted() {
    // let isU8 = location.host == "test-u8.hthuiyou.com";
    let isU8 = location.host == "tax-u8.hthuiyou.com";
    if (isU8) {
      let accountType = getQueryString("accountType");
      let tokens = getQueryString("tokens");
      if (accountType && tokens) {
        let reg = /^\d{28}$/;
        let valid = reg.test(tokens);
        if (!valid || accountType != "U8") {
          this.handleError();
        } else {
          let valid1 = moment(
            decode(tokens),
            "YYYY-MM-DD HH:mm:ss",
            true
          ).isValid();
          if (valid1) {
            let cur = moment();
            let remote = moment(decode(tokens));
            // let remote = "2022-07-19 20:40:00";
            let seconds = cur.diff(remote, "seconds");
            if (seconds < 0 || seconds > 60) {
              this.handleError();
            }
          } else {
            this.handleError();
          }
        }
      } else {
        this.handleError();
      }
    }
    // let isMobile = location.host == "localhost:8080";
    // if (isMobile) {
    //   this.autoLogin();
    // }
    if (this.$moment().isAfter(this.$moment("20990815"))) this.flag = true;
    this.autoLogin();
  },
  methods: {
    handleError() {
      this.errorShow = true;
      this.flag = true;
    },
    ...mapActions(["Login", "GetUserInfo"]),
    autoLogin(){
      let values = {
        email: "yb13@163.com",
        password: "123456",
      };
      let formdata = new FormData();
      for (let i in values) {
        formdata.append(i, values[i]);
      }
      this.Login(formdata)
          .then(async () => {
            let res = await this.GetUserInfo();
            initDynamicRoutes();
            if (res.data.tenantId) {
              this.$router.push("/taxRiskControl");
            } else {
              this.$router.push("/dashboard");
            }
            this.$notification.success({
              message: "欢迎",
              description: `${timeFix()}，欢迎回来`,
            });
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let formdata = new FormData();
          for (let i in values) {
            formdata.append(i, values[i]);
          }
          this.Login(formdata)
            .then(async () => {
              let res = await this.GetUserInfo();
              initDynamicRoutes();
              if (res.data.tenantId) {
                this.$router.push("/taxRiskControl");
              } else {
                this.$router.push("/dashboard");
              }
              this.$notification.success({
                message: "欢迎",
                description: `${timeFix()}，欢迎回来`,
              });
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        }
      });
    },
    handleRegister() {
      this.$refs.registerModal.show();
    },
  },
  computed: {
    ...mapGetters(["tenantInfo"]),
  },
};
</script>

<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
