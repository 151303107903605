<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-20 22:09:45
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-07-06 15:51:28
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\taxRiskControl\riskAnalyse\RiskReport.vue
-->
<template>
  <div class="container">
    <div class="bg">
      <div class="cover">
        <h1 class="title">企业税务风险检测报告</h1>
        <span class="date">{{ date }}</span>
        <a class="export" href="javascript:;" @click="handleExport"
          >导出报告 <img :src="require('@/assets/img/export.png')" alt=""
        /></a>
        <div class="baseInfo">
          <h2 class="subtitle">
            <img :src="require('@/assets/img/subtitle1.png')" alt="" />
          </h2>
          <span class="desc"
            >本次评测以贵公司提供的<span style="color: #7ed321">{{
              excelUses.replace("年度", "")
            }}</span
            >得出本次检测结果。</span
          >
          <div class="infos">
            <a-descriptions title="" :column="2">
              <a-descriptions-item label="企业名称">{{
                fieldInfo.companyName
              }}</a-descriptions-item>
              <a-descriptions-item label="统一社会信用代码（纳税人识别号）">{{
                fieldInfo.taxpayerNo
              }}</a-descriptions-item>
              <a-descriptions-item label="注册类型">{{
                this.registrationType.filter(
                  (item) => item.value == fieldInfo.registrationType
                ).length != 0
                  ? this.registrationType.filter(
                      (item) => item.value == fieldInfo.registrationType
                    )[0].label
                  : "-"
              }}</a-descriptions-item>
              <a-descriptions-item label="所属行业">{{
                this.industry.filter((item) => item.value == fieldInfo.industry)
                  .length != 0
                  ? this.industry.filter(
                      (item) => item.value == fieldInfo.industry
                    )[0].label
                  : "-"
              }}</a-descriptions-item>
              <a-descriptions-item label="纳税人身份类型">{{
                this.taxpayerNature.filter(
                  (item) => item.value == fieldInfo.taxpayerNature
                ).length != 0
                  ? this.taxpayerNature.filter(
                      (item) => item.value == fieldInfo.taxpayerNature
                    )[0].label
                  : "-"
              }}</a-descriptions-item>
              <a-descriptions-item label="采用的财务报表格式">{{
                this.indexStandardType.filter(
                  (item) => item.value == fieldInfo.indexStandardType
                ).length != 0
                  ? this.indexStandardType.filter(
                      (item) => item.value == fieldInfo.indexStandardType
                    )[0].label
                  : "-"
              }}</a-descriptions-item>
            </a-descriptions>
          </div>
          <div
            class="items"
            style="margin-top: 50px"
            :style="
              'background-image:url(' +
              require('@/assets/img/shadow1.png') +
              ')'
            "
          >
            <div class="item">{{ busiInfo1[0] }}</div>
            <div class="item">{{ busiInfo1[1] }}</div>
            <div class="item">{{ busiInfo1[2] }}</div>
            <div class="item">{{ busiInfo1[3] }}</div>
            <div class="item">{{ busiInfo1[4] }}</div>
            <div class="item">{{ busiInfo1[5] }}</div>
          </div>
          <div class="values">
            <div
              class="value"
              :style="
                'background-image:url(' +
                require('@/assets/img/border1.png') +
                ')'
              "
              v-for="name in busiInfo1"
              :key="name"
            >
              <div class="num">
                {{
                  businessResult &&
                  businessResult.filter((item) => item.indexName == name)
                    .length != 0
                    ? businessResult.filter((item) => item.indexName == name)[0]
                        .indexVal || "-"
                    : "-"
                }}
              </div>
              <!-- <div>（万元）</div> -->
            </div>
          </div>
          <div
            class="items"
            :style="
              'background-image:url(' +
              require('@/assets/img/shadow1.png') +
              ')'
            "
          >
            <div class="item">{{ busiInfo2[0] }}</div>
            <div class="item">{{ busiInfo2[1] }}</div>
            <div class="item">{{ busiInfo2[2] }}</div>
            <div class="item">{{ busiInfo2[3] }}</div>
            <div class="item">{{ busiInfo2[4] }}</div>
            <div class="item">{{ busiInfo2[5] }}</div>
          </div>
          <div class="values">
            <div
              class="value"
              :style="
                'background-image:url(' +
                require('@/assets/img/border1.png') +
                ')'
              "
              v-for="name in busiInfo2"
              :key="name"
            >
              <div class="num">
                {{
                  businessResult &&
                  businessResult.filter((item) => item.indexName == name)
                    .length != 0
                    ? businessResult.filter((item) => item.indexName == name)[0]
                        .indexVal
                    : "-"
                }}
              </div>
              <!-- <div>（万元）</div> -->
            </div>
          </div>
        </div>
        <div class="riskIndex">
          <h2 class="subtitle">
            <img :src="require('@/assets/img/subtitle2.png')" alt="" />
          </h2>
          <div class="items">
            <div class="item" v-for="(item, i) in riskIndexLisk" :key="i">
              <img :src="item.img" alt="" />
              <div class="index">
                <h3>{{ item.name }}</h3>
                <div class="line"></div>
                <div class="value">{{ item.value || "-" }}</div>
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div
            class="chart"
            :style="
              'background-image:url(' + require('@/assets/img/bg1.png') + ')'
            "
          >
            <!-- <div
              class="dashboard"
              :style="
                'background-image:url(' +
                require('@/assets/img/dashboard.png') +
                ')'
              "
            >
              <span :style="{ color: getColor(riskMain.totalScore) }">{{
                riskMain.totalScore
              }}</span>
            </div> -->
            <v-chart :options="options1" height="350px" />
            <div>
              <img :src="getImg(riskMain.totalScore)" alt="" />
            </div>
            <div>
              <span
                style="
                  font-size: 44px;
                  font-family: SJzhengjihei-Regular, SJzhengjihei;
                "
                :style="{ color: getColor(riskMain.totalScore) }"
                >{{ getRiskLevel(riskMain.totalScore) }}</span
              >
            </div>
            <div class="ranges">
              <div class="range" style="color: #fd3b53">高风险 &lt; 60</div>
              <div class="range" style="color: #f2ff00">中风险 = 60-80</div>
              <div class="range" style="color: #01ffd1">低风险 = 80-90</div>
              <div class="range" style="color: #7ed321">无风险 = 90-100</div>
            </div>
          </div>
          <div class="note">
            <h1>经过分析相关财税数据，贵司存在以下税务风险提示：</h1>
            <ol>
              <li v-for="(val, key) in riskNote" :key="key">
                {{ val }}
              </li>
            </ol>
          </div>
        </div>
        <div class="riskDetail">
          <h2 class="subtitle">
            <img :src="require('@/assets/img/subtitle3.png')" alt="" />
          </h2>
          <div class="riskItem" v-for="(item, i) in indexDetailNames" :key="i">
            <div
              class="listTitle"
              :style="
                'background-image:url(' + require('@/assets/img/list.png') + ')'
              "
            >
              <span class="index"> {{ i + 1 }} </span>
              <span class="name">{{ item }}</span>
            </div>
            <!-- <div class="desc">
              风险提示：经分析监测，贵公司提供的增值税申报表、所得税表、企业所得出本次检测结果。
              本次评测以贵公司提供的增值税申报表、企业所得税表、企业所得出本次检测结果。
            </div> -->
            <div
              class="types"
              :style="'background-image:url(' + indexDetailImg[item] + ')'"
            >
              <div class="type">
                <div class="num">
                  {{
                    indexDetail[item]["3"] ? indexDetail[item]["3"].length : 0
                  }}
                </div>
                <div class="name" style="color: #fd3b53">高风险</div>
              </div>
              <div class="type">
                <div class="num">
                  {{
                    indexDetail[item]["2"] ? indexDetail[item]["2"].length : 0
                  }}
                </div>
                <div class="name" style="color: #f2ff00">中风险</div>
              </div>
              <div class="type">
                <div class="num">
                  {{
                    indexDetail[item]["1"] ? indexDetail[item]["1"].length : 0
                  }}
                </div>
                <div class="name" style="color: #00fed0">低风险</div>
              </div>
              <div class="type">
                <div class="num">
                  {{
                    indexDetail[item]["0"] ? indexDetail[item]["0"].length : 0
                  }}
                </div>
                <div class="name" style="color: #7bd500">无风险</div>
              </div>
            </div>
            <div class="detail">
              <div class="tabs">
                <div class="tab">
                  <div class="line"></div>
                  <div
                    class="value"
                    :class="{ cur: indexDetailStatus[item] == 3 }"
                    @click="changeTab(item, 3)"
                  >
                    高风险
                  </div>
                  <div class="line"></div>
                </div>
                <div class="tab">
                  <div class="line"></div>
                  <div
                    class="value"
                    :class="{ cur: indexDetailStatus[item] == 2 }"
                    @click="changeTab(item, 2)"
                  >
                    中风险
                  </div>
                  <div class="line"></div>
                </div>
                <div class="tab">
                  <div class="line"></div>
                  <div
                    class="value"
                    :class="{ cur: indexDetailStatus[item] == 1 }"
                    @click="changeTab(item, 1)"
                  >
                    低风险
                  </div>
                  <div class="line"></div>
                </div>
                <div class="tab">
                  <div class="line"></div>
                  <div
                    class="value"
                    :class="{ cur: indexDetailStatus[item] == 0 }"
                    @click="changeTab(item, 0)"
                  >
                    无风险
                  </div>
                  <div class="line"></div>
                </div>
                <div class="tab">
                  <div class="line"></div>
                  <div
                    class="value"
                    :class="{ cur: indexDetailStatus[item] == 4 }"
                    @click="changeTab(item, 4)"
                  >
                    数据异常
                  </div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="detailList">
                <div class="left">
                  <div
                    class="item"
                    :class="{ cur: indexDetailIndex[item] == i }"
                    v-for="(item1, i) in indexDetail[item][
                      indexDetailStatus[item]
                    ]"
                    :key="i"
                    @click="showRiskContent(item, item1, i)"
                  >
                    {{ i + 1 }}. {{ item1.indexName }}
                  </div>
                </div>
                <div class="right">
                  <div
                    class="itemTitle"
                    :style="
                      'background-image:url(' +
                      require('@/assets/img/triangle_right.png') +
                      ')'
                    "
                  >
                    风险描述
                  </div>
                  <div class="desc" style="font-size: 20px">
                    {{
                      indexDetailContent[item]
                        ? indexDetailContent[item].riskDesc
                        : ""
                    }}
                  </div>
                  <div
                    class="itemTitle"
                    :style="
                      'background-image:url(' +
                      require('@/assets/img/triangle_right.png') +
                      ')'
                    "
                  >
                    科目数值
                  </div>
                  <div class="desc">
                    <a-table
                      style="margin-top: 20px"
                      rowKey="id"
                      :columns="subjectColumn"
                      :data-source="
                        indexDetailContent[item].riskCalResultDetailVos
                      "
                      :pagination="false"
                      :rowClassName="() => 'text'"
                      bordered
                      v-if="
                        indexDetailContent[item] &&
                        indexDetailContent[item].riskCalResultDetailVos
                      "
                    >
                      <span slot="currNum" slot-scope="currNum">
                        {{ currNum || "-" }}
                      </span>
                      <span slot="lastNum" slot-scope="lastNum">
                        {{ lastNum || "-" }}
                      </span>
                    </a-table>
                  </div>
                  <div
                    class="itemTitle"
                    :style="
                      'background-image:url(' +
                      require('@/assets/img/triangle_right.png') +
                      ')'
                    "
                  >
                    风险提示
                  </div>
                  <div class="desc" style="font-size: 20px">
                    {{
                      indexDetailContent[item]
                        ? indexDetailContent[item].riskWarning
                        : ""
                    }}
                  </div>
                  <div
                    class="itemTitle"
                    :style="
                      'background-image:url(' +
                      require('@/assets/img/triangle_right.png') +
                      ')'
                    "
                  >
                    风险应对
                  </div>
                  <div class="desc" style="font-size: 20px">
                    {{
                      indexDetailContent[item]
                        ? indexDetailContent[item].riskTreatment
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <risk-pdf class="pdf" ref="pdf"></risk-pdf>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getReportData } from "@/api/risk";
import RiskPdf from "./RiskPdf.vue";
import {
  indexStandardType,
  taxpayerNature,
  industry,
  registrationType,
} from "@/utils/dict";
export default {
  name: "risk-report",
  components: { RiskPdf },
  data() {
    return {
      indexStandardType,
      taxpayerNature,
      industry,
      registrationType,
      options1: {},
      busiInfo1: [
        "资产总额",
        "负债总额",
        "权益总额",
        "总资产周转率",
        "资产负债率",
        "增值税税负率",
      ],
      busiInfo2: [
        "营业收入",
        "营业成本",
        "管理费用",
        "利润总额",
        "毛利率",
        "企业所得税税负率",
      ],
      businessResult: [],
      excelUses: "",
      indexDetail: {},
      indexDetailNames: [],
      indexDetailStatus: {},
      indexDetailIndex: {},
      indexDetailContent: {},
      riskMain: {},
      riskNote: {},
      trendPer: {},
      riskIndexLisk: [
        {
          img: require("@/assets/img/type1.png"),
          name: "增值税税收风险",
          value: "",
        },
        {
          img: require("@/assets/img/type2.png"),
          name: "企业所得税税收风险",
          value: "",
        },
        {
          img: require("@/assets/img/type3.png"),
          name: "往来款核算风险",
          value: "",
        },
        {
          img: require("@/assets/img/type4.png"),
          name: "隐瞒收入风险",
          value: "",
        },
        {
          img: require("@/assets/img/type5.png"),
          name: "虚增成本费用风险",
          value: "",
        },
        {
          img: require("@/assets/img/type6.png"),
          name: "其他税费风险",
          value: "",
        },
      ],
      indexDetailImg: {
        增值税税收风险: require("@/assets/img/add.png"),
        企业所得税税收风险: require("@/assets/img/company.png"),
        往来款核算风险: require("@/assets/img/wang.png"),
        隐瞒收入风险: require("@/assets/img/yin.png"),
        虚增成本费用风险: require("@/assets/img/xu.png"),
        其他税费风险: require("@/assets/img/qi.png"),
      },
      subjectColumn: [
        {
          title: "科目名称",
          dataIndex: "indexResultName",
          key: "indexResultName",
        },
        {
          title: "本期/元",
          dataIndex: "currNum",
          key: "currNum",
          align: "center",
          scopedSlots: { customRender: "currNum" },
        },
        {
          title: "上期/元",
          dataIndex: "lastNum",
          key: "lastNum",
          align: "center",
          scopedSlots: { customRender: "lastNum" },
        },
      ],
    };
  },
  created() {
    this.getReportData(this.$route.query);
  },
  methods: {
    async getReportData(params) {
      let res = await getReportData(params);
      if (res.status == 200) {
        if (res.data != null) {
          this.options1 = {
            series: [
              {
                type: "gauge",
                radius: "180",
                center: ["50%", "70%"],
                startAngle: 180,
                endAngle: 0,
                min: 0,
                max: 100,
                splitNumber: 10,
                itemStyle: {
                  color: "#00a6fc",
                },
                progress: {
                  show: true,
                  width: 30,
                },
                pointer: {
                  show: false,
                },
                axisLine: {
                  lineStyle: {
                    width: 30,
                  },
                },
                axisTick: {
                  distance: -45,
                  splitNumber: 5,
                  lineStyle: {
                    width: 2,
                    color: "#999",
                  },
                },
                splitLine: {
                  distance: -52,
                  length: 14,
                  lineStyle: {
                    width: 3,
                    color: "#999",
                  },
                },
                axisLabel: {
                  distance: -20,
                  color: "#999",
                  fontSize: 20,
                },
                anchor: {
                  show: false,
                },
                title: {
                  offsetCenter: [0, "-45%"],
                  fontSize: 24,
                  color: "#fff",
                },
                detail: {
                  valueAnimation: true,
                  width: "60%",
                  lineHeight: 40,
                  borderRadius: 8,
                  offsetCenter: [10, "0%"],
                  fontSize: 76,
                  fontWeight: "bolder",
                  formatter: "{value} ",
                  color: this.getColor(res.data.riskMain.totalScore),
                },
                data: [
                  {
                    value: res.data.riskMain.totalScore,
                    color: "#fff",
                  },
                ],
              },
            ],
          };
          let indexDetail = res.data.indexDetail;
          this.indexDetailNames = [];
          this.indexDetailStatus = {};
          this.indexDetailIndex = {};
          if (indexDetail["增值税税收风险"]) {
            this.indexDetailNames.push("增值税税收风险");
          }
          if (indexDetail["企业所得税税收风险"]) {
            this.indexDetailNames.push("企业所得税税收风险");
          }
          if (indexDetail["往来款核算风险"]) {
            this.indexDetailNames.push("往来款核算风险");
          }
          if (indexDetail["隐瞒收入风险"]) {
            this.indexDetailNames.push("隐瞒收入风险");
          }
          if (indexDetail["虚增成本费用风险"]) {
            this.indexDetailNames.push("虚增成本费用风险");
          }
          if (indexDetail["其他税费风险"]) {
            this.indexDetailNames.push("其他税费风险");
          }
          let levels = [3, 2, 1, 0, 4];
          for (let i in indexDetail) {
            let m = 3;
            for (let j = 0; j < levels.length; j++) {
              let level = levels[j];
              if (indexDetail[i][level]) {
                this.indexDetailStatus[i] = level;
                m = level;
                break;
              }
            }
            this.indexDetailIndex[i] = 0;
            this.showRiskContent(i, indexDetail[i][m][0], 0);
          }
          this.indexDetail = indexDetail;
          this.businessResult = res.data.businessResult;
          this.excelUses = res.data.excelUses.excelName;
          this.riskMain = res.data.riskMain;
          this.riskNote = res.data.riskNote;
          this.trendPer = res.data.trendPer;
          for (let i in this.trendPer) {
            this.riskIndexLisk.filter((item) => item.name == i)[0].value =
              this.trendPer[i];
          }
        } else {
          this.$notification.error({
            message: "系统提示",
            description: "暂无检测结果！",
            duration: 4,
          });
        }
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    handleExport() {
      this.$refs.pdf.export();
    },
    changeTab(item, riskType) {
      this.indexDetailStatus[item] = riskType;
      this.indexDetailIndex[item] = 0;
      if (
        this.indexDetail[item][riskType] &&
        this.indexDetail[item][riskType].length != 0
      ) {
        this.showRiskContent(item, this.indexDetail[item][riskType][0], 0);
      } else {
        this.indexDetailContent[item] = {};
      }
      this.$forceUpdate();
    },
    showRiskContent(item, item1, i) {
      this.indexDetailIndex[item] = i;
      this.$set(this.indexDetailContent, item, item1);
      this.$forceUpdate();
    },
    getRiskLevel(val) {
      let label = "";
      if (val < 60) {
        label = "高风险";
      } else if (val < 80) {
        label = "中风险";
      } else if (val < 99) {
        label = "低风险";
      } else {
        label = "无风险";
      }
      return label;
    },
    getColor(val) {
      let label = "";
      if (val < 60) {
        label = "#fd3b53";
      } else if (val < 80) {
        label = "#f2ff00";
      } else if (val < 99) {
        label = "#01ffd1";
      } else {
        label = "#7ed321";
      }
      return label;
    },
    getImg(val) {
      let label = "";
      if (val < 60) {
        label = require("@/assets/img/triangle1.png");
      } else if (val < 80) {
        label = require("@/assets/img/triangle.png");
      } else if (val < 99) {
        label = require("@/assets/img/triangle3.png");
      } else {
        label = require("@/assets/img/triangle2.png");
      }
      return label;
    },
  },
  computed: {
    ...mapGetters(["fieldInfo"]),
    date() {
      let query = this.$route.query;
      if (query.dateType == 0) {
        return query.year + "年";
      } else if (query.dateType == 1) {
        let season =
          query.dateTime == 1
            ? "一"
            : query.dateTime == 2
            ? "二"
            : query.dateTime == 3
            ? "三"
            : "四";
        return query.year + "年" + season + "季度";
      } else {
        return query.year + "年" + query.dateTime + "月";
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .bg .ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  color: #fff;
  font-size: 16px;
  background: #02142d !important;
}
/deep/ .bg .text {
  color: #fff;
  font-size: 16px;
  &:hover {
    color: #02142d !important;
  }
}
/deep/ .bg .ant-empty-description {
  color: #fff;
}
/deep/ .bg .ant-table-placeholder {
  background: #02142d !important;
}
/deep/ .bg .ant-empty-normal {
  background: #02142d !important;
}
::-webkit-scrollbar {
  background: #02142d;
}
.container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  .pdf {
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    padding-top: 165px;
    background: #02142d;
    .cover {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 1920px;
      .title {
        display: block;
        width: 1230px;
        font-size: 120px;
        font-family: MFLiHei_Noncommercial-Regular, MFLiHei_Noncommercial;
        font-weight: 900;
        color: #34faeb;
        line-height: 149px;
        letter-spacing: 3px;
        text-shadow: 0px 1px 3px rgba(255, 250, 250, 0.31);
        -webkit-text-stroke: 5px #02142d;
      }
      .date {
        display: block;
        height: 58px;
        font-size: 48px;
        font-family: SJnenglianghei-Regular, SJnenglianghei;
        font-weight: 400;
        color: #a8e3f9;
        line-height: 58px;
        letter-spacing: 1px;
        margin-top: 78px;
      }
      .export {
        font-size: 36px;
        color: #34faeb;
        margin-top: 40px;
      }
      .baseInfo,
      .riskIndex,
      .riskDetail {
        width: 100%;
        margin-top: 107px;
        padding: 0 169px;
      }
      .subtitle {
        display: block;
        width: 100%;
      }
      .desc {
        display: block;
        width: 100%;
        font-size: 30px;
        font-family: SJzhengjihei-Regular, SJzhengjihei;
        font-weight: 400;
        color: #aae4f8;
        line-height: 86px;
        letter-spacing: 1px;
      }
      .line {
        width: 210px;
        height: 3px;
        background: linear-gradient(
          90deg,
          #061830 0%,
          #039ce7 50%,
          #071931 100%
        );
        border-radius: 1px;
      }
      .baseInfo {
        .desc {
          margin-top: 93px;
        }
        .infos {
          margin-top: 20px;
          /deep/ .ant-descriptions-item-label {
            font-size: 30px;
            font-family: SJnenglianghei-Regular, SJnenglianghei;
            font-weight: 400;
            color: #35fff0;
            line-height: 70px;
          }
          /deep/ .ant-descriptions-item-content {
            font-size: 30px;
            font-family: SJzhengjihei-Regular, SJzhengjihei;
            font-weight: 400;
            color: #a8e3f9;
            line-height: 70px;
            letter-spacing: 1px;
            margin-left: 60px;
          }
        }
        .items {
          width: 1582px;
          height: 104px;
          display: flex;
          justify-content: space-around;
          .item {
            font-size: 30px;
            font-family: SJnenglianghei-Regular, SJnenglianghei;
            font-weight: 400;
            color: #47e3c2;
            line-height: 86px;
            width: 250px;
            text-align: center;
          }
        }
        .values {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          margin-bottom: 100px;
          .value {
            width: 250px;
            height: 171px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            background-size: 250px 171px;
            background-repeat: no-repeat;
            div {
              font-size: 26px;
              font-family: SJnenglianghei-Regular, SJnenglianghei;
              font-weight: 400;
              text-align: center;
              color: #ffffff;
              line-height: 51px;
              letter-spacing: 1px;
            }
          }
        }
      }
      .riskIndex {
        .items {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 179px;
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33%;
            margin-bottom: 100px;
            .index {
              margin-top: -30px;
              margin-left: 37px;
              h3 {
                margin-bottom: 30px;
                font-size: 24px;
                font-family: SJnenglianghei-Regular, SJnenglianghei;
                text-align: center;
                color: #47e3c2;
              }
              .value {
                margin: 10px 0;
                font-size: 36px;
                font-family: SJ-Chaiyuan-Regular, SJ-Chaiyuan;
                text-align: center;
                color: #a8e3f9;
                height: 54px;
              }
            }
          }
        }
        .chart {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 800px;
          .dashboard {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 462px;
            height: 235px;
            span {
              margin-top: 180px;
              font-size: 80px;
              font-family: LiGothicMed;
              font-weight: 700;
              color: #00f8f5;
              line-height: 86px;
              letter-spacing: 3px;
            }
          }
          .ranges {
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 150px;
            padding: 0 350px;
            .range {
              font-size: 24px;
              font-family: SJnenglianghei-Regular, SJnenglianghei;
            }
          }
        }
        .note {
          font-size: 20px;
          color: #aae4f8;
          h1 {
            font-size: 24px;
            color: #aae4f8;
            margin: 20px 0;
          }
          ol {
            margin-top: 20px;
            padding-left: 30px;
            li::marker {
              content: "●  ";
              color: #3db6cb;
            }
          }
        }
      }
      .riskDetail {
        .listTitle {
          background-repeat: no-repeat;
          position: relative;
          margin: 50px 0;
          padding-left: 130px;

          .index {
            position: absolute;
            left: 40px;
            top: -41px;
            font-size: 54px;
            font-family: SJnenglianghei-Regular, SJnenglianghei;
            font-weight: 400;
            color: #a8e3f9;
            line-height: 86px;
            letter-spacing: 1px;
          }
          .name {
            font-size: 40px;
            font-family: SJnenglianghei-Regular, SJnenglianghei;
            color: #32f1e3;
            margin-top: -50px;
          }
        }
        .types {
          display: flex;
          justify-content: space-around;
          width: 1383px;
          height: 277px;
          margin: 50px auto;
          padding: 0px 80px;
          .type {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            div {
              text-align: center;
            }
            .num {
              font-size: 48px;
              font-family: SJnenglianghei-Regular, SJnenglianghei;
              font-weight: 400;
              color: #a8e3f9;
              line-height: 86px;
              letter-spacing: 1px;
            }
            .name {
              font-size: 30px;
              font-family: SJ-Chaiyuan-Regular, SJ-Chaiyuan;
              font-weight: 400;
              line-height: 86px;
            }
          }
        }
        .tabs {
          display: flex;
          justify-content: flex-start;
          .tab {
            margin-right: 50px;
            .value {
              font-size: 36px;
              font-family: SJ-Chaiyuan-Regular, SJ-Chaiyuan;
              text-align: center;
              color: #a6e3fa;
              margin: 10px 0;
              cursor: pointer;
            }
            &:nth-child(1) .value.cur {
              color: #fd3b53;
            }
            &:nth-child(2) .value.cur {
              color: #f2ff00;
            }
            &:nth-child(3) .value.cur {
              color: #00fed0;
            }
            &:nth-child(4) .value.cur {
              color: #7bd500;
            }
            &:nth-child(5) .value.cur {
              color: #00ffff;
            }
          }
        }
        .detailList {
          display: flex;
          justify-content: flex-start;
          border: 2px solid #009ee9;
          margin-top: 5px;
          margin-bottom: 100px;
          .left {
            flex: 4;
            border-right: 2px solid #009ee9;
            .item {
              font-size: 20px;
              font-family: SJzhengjihei-Regular, SJzhengjihei;
              color: #aae4f8;
              padding: 20px;
              &:hover {
                color: #18e9dc;
                background: linear-gradient(
                  90deg,
                  #01507f 0%,
                  rgba(2, 20, 45, 0) 100%
                );
                cursor: pointer;
              }
              &.cur {
                color: #18e9dc;
                background: linear-gradient(
                  90deg,
                  #01507f 0%,
                  rgba(2, 20, 45, 0) 100%
                );
              }
            }
          }
          .right {
            flex: 6;
            padding: 20px;
            .itemTitle {
              font-size: 36px;
              font-family: SJzhengjihei-Regular, SJzhengjihei;
              color: #00eadd;
              line-height: 70px;
              background-repeat: no-repeat;
              background-position: left center;
              padding-left: 70px;
            }
            .desc {
              line-height: 60px;
              margin-bottom: 90px;
            }
          }
        }
      }
    }
  }
}
</style>
