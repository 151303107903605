<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-13 13:08:26
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-10 16:32:40
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\taxRiskControl\dataCollect\Table.vue
-->
<template>
  <div>
    <div class="options">
      <a-form layout="inline">
        <a-form-item label="申报时期">
          <a-button-group style="margin-left: 20px">
            <a-button
              :type="dataType == 0 ? 'primary' : ''"
              @click="
                dataType = 0;
                getUploadStatus();
              "
              >年度</a-button
            >
            <a-button
              :type="dataType == 1 ? 'primary' : ''"
              @click="
                dataType = 1;
                getUploadStatus();
              "
              >季度</a-button
            >
            <a-button
              :type="dataType == 2 ? 'primary' : ''"
              @click="
                dataType = 2;
                getUploadStatus();
              "
              v-if="fieldInfo.taxpayerNature != 1"
              >月度</a-button
            >
          </a-button-group>
        </a-form-item>
        <a-form-item label="时间" style="margin-left: 20px">
          <a-select
            placeholder="请选择年份"
            style="width: 200px; margin-left: 20px"
            v-model="year"
            @change="getUploadStatus"
          >
            <a-select-option v-for="item in yearList" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-space>
            <a-button @click="handleDownload">历史版本模板</a-button>
            <!-- <a-upload
              :file-list="fileList"
              :remove="handleRemove"
              :before-upload="beforeUpload"
            >
              <a-button type="primary">
                <a-icon type="upload" /> 选择文件
              </a-button>
            </a-upload>
            <a-button @click="handleUploadAll">上传</a-button> -->
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <div class="table">
      <div class="card-container">
        <a-tabs type="card" v-model="excelName" @change="getUploadStatus">
          <a-tab-pane key="增值税纳税申报表" tab="增值税纳税申报表">
            <a-table
              class="mtable"
              :columns="columns"
              :data-source="datas[dataType][0]"
              :pagination="false"
              :locale="locale"
              :rowClassName="
                (record, index) => (index % 2 === 0 ? 'table-striped' : null)
              "
            >
              <span slot="time" slot-scope="time">
                {{ time ? $moment(time).format("YYYY-MM-DD HH:mm:ss") : "" }}
              </span>
              <span slot="status" slot-scope="status">
                <a-tag
                  :key="status"
                  :color="status == '1' ? 'green' : 'orange'"
                >
                  {{ statusFormatter(status) }}
                </a-tag>
              </span>
              <span slot="action" slot-scope="text, record">
                <a-upload
                  name="file"
                  :showUploadList="false"
                  :custom-request="(e) => handleUpload(e, record)"
                >
                  <a-button type="primary">
                    <a-icon type="upload" /> 上传文件
                  </a-button>
                </a-upload>
              </span>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="财务报表" tab="财务报表">
            <a-table
              class="mtable"
              :columns="columns"
              :data-source="datas[dataType][1]"
              :pagination="false"
              :locale="locale"
              :rowClassName="
                (record, index) => (index % 2 === 0 ? 'table-striped' : null)
              "
            >
              <span slot="time" slot-scope="time">
                {{ time ? $moment(time).format("YYYY-MM-DD HH:mm:ss") : "" }}
              </span>
              <span slot="status" slot-scope="status">
                <a-tag
                  :key="status"
                  :color="status == '1' ? 'green' : 'orange'"
                >
                  {{ statusFormatter(status) }}
                </a-tag>
              </span>
              <span slot="action" slot-scope="text, record">
                <a-upload
                  name="file"
                  :showUploadList="false"
                  :custom-request="(e) => handleUpload(e, record)"
                >
                  <a-button type="primary">
                    <a-icon type="upload" /> 上传文件
                  </a-button>
                </a-upload>
              </span>
            </a-table>
          </a-tab-pane>
          <a-tab-pane
            key="企业所得税月（季）度预缴纳税申报表"
            tab="企业所得税月（季）度预缴纳税申报表"
          >
            <a-table
              class="mtable"
              :columns="columns"
              :data-source="datas[dataType][2]"
              :pagination="false"
              :locale="locale"
              :rowClassName="
                (record, index) => (index % 2 === 0 ? 'table-striped' : null)
              "
            >
              <span slot="time" slot-scope="time">
                {{ time ? $moment(time).format("YYYY-MM-DD HH:mm:ss") : "" }}
              </span>
              <span slot="status" slot-scope="status">
                <a-tag
                  :key="status"
                  :color="status == '1' ? 'green' : 'orange'"
                >
                  {{ statusFormatter(status) }}
                </a-tag>
              </span>
              <span slot="action" slot-scope="text, record">
                <a-upload
                  name="file"
                  :showUploadList="false"
                  :custom-request="(e) => handleUpload(e, record)"
                >
                  <a-button type="primary">
                    <a-icon type="upload" /> 上传文件
                  </a-button>
                </a-upload>
              </span>
            </a-table>
          </a-tab-pane>
          <a-tab-pane
            key="企业所得税年度纳税申报表"
            tab="企业所得税年度纳税申报表"
          >
            <a-table
              class="mtable"
              :columns="columns"
              :data-source="datas[dataType][3]"
              :pagination="false"
              :locale="locale"
              :rowClassName="
                (record, index) => (index % 2 === 0 ? 'table-striped' : null)
              "
            >
              <span slot="time" slot-scope="time">
                {{ time ? $moment(time).format("YYYY-MM-DD HH:mm:ss") : "" }}
              </span>
              <span slot="status" slot-scope="status">
                <a-tag
                  :key="status"
                  :color="status == '1' ? 'green' : 'orange'"
                >
                  {{ statusFormatter(status) }}
                </a-tag>
              </span>
              <span slot="action" slot-scope="text, record">
                <a-upload
                  name="file"
                  :showUploadList="false"
                  :custom-request="(e) => handleUpload(e, record)"
                >
                  <a-button type="primary">
                    <a-icon type="upload" /> 上传文件
                  </a-button>
                </a-upload>
              </span>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  getYear,
  getexcel,
  // getexcelAll,
  getUploadStatus,
} from "@/api/tax";
import { dowloadTmp } from "@/api/template";
import Empty from "./Empty.vue";
const columns = [
  {
    title: "序号",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
    align: "center",
  },
  {
    title: "上传时间",
    dataIndex: "time",
    key: "time",
    align: "center",
    scopedSlots: { customRender: "time" },
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "",
  data() {
    return {
      // fileList: [],
      locale: { emptyText: <Empty /> },
      dataType: 0,
      year: 0,
      yearList: [],
      excelName: "增值税纳税申报表",
      datas: [
        [
          [
            {
              id: 1,
              name: "第12月增值税申报表",
              time: "",
              status: -1,
            },
          ],
          [
            {
              id: 1,
              name: "年度财务报表",
              time: "",
              status: -1,
            },
          ],
          [],
          [
            {
              id: 1,
              name: "年度所得税申报表",
              time: "",
              status: -1,
            },
          ],
        ],
        [
          [
            {
              id: 1,
              name: "一季度末增值税申报表",
              time: "",
              status: -1,
            },
            {
              id: 2,
              name: "二季度末增值税申报表",
              time: "",
              status: -1,
            },
            {
              id: 3,
              name: "三季度末增值税申报表",
              time: "",
              status: -1,
            },
            {
              id: 4,
              name: "四季度末增值税申报表",
              time: "",
              status: -1,
            },
          ],
          [
            {
              id: 1,
              name: "一季度财务报表",
              time: "",
              status: -1,
            },
            {
              id: 2,
              name: "二季度财务报表",
              time: "",
              status: -1,
            },
            {
              id: 3,
              name: "三季度财务报表",
              time: "",
              status: -1,
            },
            {
              id: 4,
              name: "四季度财务报表",
              time: "",
              status: -1,
            },
          ],
          [
            {
              id: 1,
              name: "一季度企业所得税预缴表",
              time: "",
              status: -1,
            },
            {
              id: 2,
              name: "二季度企业所得税预缴表",
              time: "",
              status: -1,
            },
            {
              id: 3,
              name: "三季度企业所得税预缴表",
              time: "",
              status: -1,
            },
            {
              id: 4,
              name: "四季度企业所得税预缴表",
              time: "",
              status: -1,
            },
          ],
          [],
        ],
        [
          [
            {
              id: 1,
              name: "一月申报表",
              time: "",
              status: -1,
            },
            {
              id: 2,
              name: "二月申报表",
              time: "",
              status: -1,
            },
            {
              id: 3,
              name: "三月申报表",
              time: "",
              status: -1,
            },
            {
              id: 4,
              name: "四月申报表",
              time: "",
              status: -1,
            },
            {
              id: 5,
              name: "五月申报表",
              time: "",
              status: -1,
            },
            {
              id: 6,
              name: "六月申报表",
              time: "",
              status: -1,
            },
            {
              id: 7,
              name: "七月申报表",
              time: "",
              status: -1,
            },
            {
              id: 8,
              name: "八月申报表",
              time: "",
              status: -1,
            },
            {
              id: 9,
              name: "九月申报表",
              time: "",
              status: -1,
            },
            {
              id: 10,
              name: "十月申报表",
              time: "",
              status: -1,
            },
            {
              id: 11,
              name: "十一月申报表",
              time: "",
              status: -1,
            },
            {
              id: 12,
              name: "十二月申报表",
              time: "",
              status: -1,
            },
          ],
          [
            {
              id: 1,
              name: "一月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 2,
              name: "二月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 3,
              name: "三月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 4,
              name: "四月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 5,
              name: "五月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 6,
              name: "六月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 7,
              name: "七月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 8,
              name: "八月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 9,
              name: "九月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 10,
              name: "十月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 11,
              name: "十一月财务报表",
              time: "",
              status: -1,
            },
            {
              id: 12,
              name: "十二月财务报表",
              time: "",
              status: -1,
            },
          ],
          [],
          [],
        ],
      ],
      columns,
    };
  },
  async created() {
    await this.getYear();
    await this.getUploadStatus();
  },
  methods: {
    // handleRemove(file) {
    //   const index = this.fileList.indexOf(file);
    //   const newFileList = this.fileList.slice();
    //   newFileList.splice(index, 1);
    //   this.fileList = newFileList;
    // },
    // beforeUpload(file) {
    //   this.fileList = [...this.fileList, file];
    //   return false;
    // },
    async getYear() {
      let params = {
        type: 1,
      };
      let res = await getYear(params);
      if (res.status == 200) {
        let years = res.data;
        this.yearList = years;
        this.year = years[0];
      }
    },
    statusFormatter(status) {
      if (status == 1) {
        return "已上传";
      } else {
        return "未上传";
      }
    },
    async getUploadStatus() {
      let params = {
        year: this.year,
        dateType: this.dataType,
        indexStandardType: this.fieldInfo.indexStandardType,
        taxpayerNature: this.fieldInfo.taxpayerNature,
        excelName: this.excelName,
        dateSource: 0,
      };
      let res = await getUploadStatus(params);
      if (res.status == 200) {
        let index = 0;
        switch (this.excelName) {
          case "增值税纳税申报表":
            index = 0;
            break;
          case "财务报表":
            index = 1;
            break;
          case "企业所得税月（季）度预缴纳税申报表":
            index = 2;
            break;
          case "企业所得税年度纳税申报表":
            index = 3;
            break;
        }
        let data = res.data;
        if (data != null) {
          data.forEach((item) => {
            if (this.dataType == 0) {
              this.datas[this.dataType][index][0].time = item.updateTime;
              this.datas[this.dataType][index][0].status = 1;
            } else {
              this.datas[this.dataType][index][item.dateTime - 1].time =
                item.updateTime;
              this.datas[this.dataType][index][item.dateTime - 1].status = 1;
            }
          });
        } else {
          this.datas[this.dataType][index].forEach((item) => {
            item.time = "";
            item.status = -1;
          });
        }
      }
    },
    async handleUpload(file, record) {
      let fileName = file.file.name;
      let formData = new FormData();
      formData.append("file", file.file, fileName);
      let query = {
        year: this.year,
        dateType: this.dataType,
        indexStandardType: this.fieldInfo.indexStandardType,
        taxpayerNature: this.fieldInfo.taxpayerNature,
        excelName: this.excelName,
        dateTime: this.dataType == 0 ? this.year : record.id,
        dateSource: 0,
      };
      let res = await getexcel(formData, query);
      if (res.status == 200) {
        this.$message.success("上传成功！");
        this.getUploadStatus();
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },

    // async handleUploadAll() {
    //   console.log(this.fileList);
    //   let formData = new FormData();
    //   // this.fileList.forEach((file) => {
    //   //   formData.append("files[]", file);
    //   // });
    //   // let fileName = file.file.name;
    //   // let formData = new FormData();
    //   formData.append("files", this.fileList);
    //   let query = {
    //     year: this.year,
    //     dateType: this.dataType,
    //     dateTime: 1,
    //     dateSource: 0,
    //   };
    //   // if (query) {
    //   //   console.log(formData);
    //   //   return;
    //   // }
    //   let res = await getexcelAll(formData, query);
    //   if (res.status == 200) {
    //     this.$message.success("上传成功！");
    //     this.getUploadStatus();
    //   } else {
    //     let msg = res.msg || res.message;
    //     let len = msg.length;
    //     this.$notification.error({
    //       message: "系统提示",
    //       description: len > 100 ? "系统错误，请联系管理员" : msg,
    //       duration: 4,
    //     });
    //   }
    // },

    async handleDownload() {
      let params = {
        year: this.year,
        dateType: this.dataType,
        indexStandardType: this.fieldInfo.indexStandardType,
        taxpayerNature: this.fieldInfo.taxpayerNature,
        excelName: this.excelName,
      };
      let res = await dowloadTmp(params);
      if (res.data.size != 0) {
        let data = res.data;
        let filename = decodeURIComponent(
          res.headers["content-disposition"].split("=")[1]
        );
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(new Blob([data]));
        } else {
          let url = window.URL.createObjectURL(new Blob([data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      } else {
        this.$message.warning("暂无文件！");
      }
    },
  },
  computed: {
    ...mapGetters(["fieldInfo"]),
  },
};
</script>
<style lang="less" scoped>
.options {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 20px;
  padding: 20px;
  background: #272d4b;
  /deep/ .ant-form-item-label > label {
    color: #fff;
  }
  .ant-btn {
    color: #fff;
    background: #2d334f;
    border: none;
  }
  .ant-btn-primary {
    color: #fff;
    background: #283a6c;
    border: none;
  }
  /deep/ .ant-select-selection {
    color: #fff;
    background: #283a6c;
    border: none;
  }
}

.table {
  .ant-btn-primary {
    color: #fff;
    background: #283a6c;
    border: none;
  }
  /deep/ .mtable .table-striped td {
    background-color: #202441 !important;
  }
  .card-container {
    background: #202441;
    overflow: hidden;
    /deep/ .ant-tabs-card > .ant-tabs-bar {
      color: #fff;
      border-color: transparent;
      background: transparent;
      .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
      }
    }
    /deep/ .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
      background: #272d4b !important;
      border-color: #272d4b !important;
    }
    /deep/ .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
      background: #272d4b !important;
    }
  }
  /deep/ .card-container > .ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;
  }
  /deep/
    .card-container
    > .ant-tabs-card
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 16px;
  }
  /deep/ .ant-table-thead > tr > th {
    color: #fff;
    background: #272d4b !important;
    border-color: #272d4b !important;
  }
  /deep/ .ant-table-tbody > tr > td {
    color: #fff;
    background: #272d4b !important;
    border-color: #272d4b !important;
  }
  /deep/ .ant-table-placeholder {
    background: #272d4b !important;
    border-color: #272d4b !important;
  }
}
</style>
