var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"账号注册","width":800,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"maskClosable":false,"okText":"注册"},on:{"ok":_vm.handleSave,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 }}},[_c('a-descriptions',{attrs:{"title":"基本信息","column":3}}),_c('a-form-item',{attrs:{"label":"账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contactEmail',
          {
            rules: [{ validator: this.handleAccount }],
            trigger: 'blur',
          },
        ]),expression:"[\n          'contactEmail',\n          {\n            rules: [{ validator: this.handleAccount }],\n            trigger: 'blur',\n          },\n        ]"}],attrs:{"placeholder":"请输入邮箱"}})],1),_c('a-popover',{attrs:{"placement":"rightTop","trigger":"click","visible":_vm.state.passwordLevelChecked}},[_c('template',{slot:"content"},[_c('div',{style:({ width: '240px' })},[_c('div',{class:['user-register', _vm.passwordLevelClass]},[_vm._v(" 强度："),_c('span',[_vm._v(_vm._s(_vm.passwordLevelName))])]),_c('a-progress',{attrs:{"percent":_vm.state.percent,"showInfo":false,"strokeColor":_vm.passwordLevelColor}}),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',[_vm._v("请至少输入 8 个字符。请不要使用容易被猜到的密码。")])])],1)]),_c('a-form-item',{attrs:{"label":"密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ validator: this.handlePasswordLevel }],
              trigger: 'blur',
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [{ validator: this.handlePasswordLevel }],\n              trigger: 'blur',\n            },\n          ]"}],attrs:{"type":"password","autocomplete":"new-password","placeholder":"至少8位密码，区分大小写"},on:{"focus":function($event){return _vm.handlePasswordInputClick(true)},"blur":function($event){return _vm.handlePasswordInputClick(false)}}})],1)],2),_c('a-form-item',{attrs:{"label":"确认密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password2',
          {
            rules: [
              { required: true, message: '确认密码不能为空' },
              { validator: this.handlePasswordCheck },
            ],
            trigger: 'blur',
          },
        ]),expression:"[\n          'password2',\n          {\n            rules: [\n              { required: true, message: '确认密码不能为空' },\n              { validator: this.handlePasswordCheck },\n            ],\n            trigger: 'blur',\n          },\n        ]"}],attrs:{"type":"password","autocomplete":"false","placeholder":"确认密码"}})],1),(_vm.isU8c)?_c('a-form-item',{attrs:{"label":"激活码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'cdk',
          {
            rules: [{ required: true, message: '激活码不能为空' }],
            trigger: 'blur',
          },
        ]),expression:"[\n          'cdk',\n          {\n            rules: [{ required: true, message: '激活码不能为空' }],\n            trigger: 'blur',\n          },\n        ]"}],attrs:{"placeholder":"请输入激活码"}})],1):_vm._e(),_c('a-descriptions',{attrs:{"title":"企业信息","column":3}}),_c('a-form-item',{attrs:{"label":"企业名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: '企业名称不能为空' }],
            trigger: 'blur',
          },
        ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: '企业名称不能为空' }],\n            trigger: 'blur',\n          },\n        ]"}],attrs:{"placeholder":"请输入企业名称"}})],1),_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contactPerson',
          {
            rules: [{ required: true, message: '联系人不能为空' }],
            trigger: 'blur',
          },
        ]),expression:"[\n          'contactPerson',\n          {\n            rules: [{ required: true, message: '联系人不能为空' }],\n            trigger: 'blur',\n          },\n        ]"}],attrs:{"placeholder":"请输入联系人"}})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contactMobile',
          {
            rules: [{ required: true, message: '联系电话不能为空' }],
            trigger: 'blur',
          },
        ]),expression:"[\n          'contactMobile',\n          {\n            rules: [{ required: true, message: '联系电话不能为空' }],\n            trigger: 'blur',\n          },\n        ]"}],attrs:{"placeholder":"请输入手机号"}})],1),_c('a-form-item',{attrs:{"label":"纳税人识别号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'taxpayerNo',
          {
            rules: [
              {
                required: true,
                message: '纳税人识别号不能为空',
              },
            ],
            trigger: 'blur',
          },
        ]),expression:"[\n          'taxpayerNo',\n          {\n            rules: [\n              {\n                required: true,\n                message: '纳税人识别号不能为空',\n              },\n            ],\n            trigger: 'blur',\n          },\n        ]"}],attrs:{"placeholder":"请输入纳税人识别号"}})],1),_c('a-form-item',{attrs:{"label":"纳税人性质"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'taxpayerNature',
          {
            rules: [{ required: true, message: '纳税人性质不能为空' }],
          },
        ]),expression:"[\n          'taxpayerNature',\n          {\n            rules: [{ required: true, message: '纳税人性质不能为空' }],\n          },\n        ]"}],attrs:{"placeholder":"请选择纳税人性质"}},_vm._l((_vm.taxpayerNature),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"会计准则类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'indexStandardType',
          {
            rules: [{ required: true, message: '会计准则类型不能为空' }],
          },
        ]),expression:"[\n          'indexStandardType',\n          {\n            rules: [{ required: true, message: '会计准则类型不能为空' }],\n          },\n        ]"}],attrs:{"placeholder":"请选择会计准则类型"}},_vm._l((_vm.indexStandardType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"注册类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'registrationType',
          {
            rules: [{ required: true, message: '注册类型不能为空' }],
          },
        ]),expression:"[\n          'registrationType',\n          {\n            rules: [{ required: true, message: '注册类型不能为空' }],\n          },\n        ]"}],attrs:{"placeholder":"请选择注册类型"}},_vm._l((_vm.registrationType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"所属行业"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'industry',
          {
            rules: [{ required: true, message: '所属行业不能为空' }],
          },
        ]),expression:"[\n          'industry',\n          {\n            rules: [{ required: true, message: '所属行业不能为空' }],\n          },\n        ]"}],attrs:{"placeholder":"请选择所属行业"}},_vm._l((_vm.industry),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }