var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"cover",attrs:{"id":"report"}},[_c('div',{staticClass:"homePage"},[(_vm.$route.query.token)?_c('div',{staticClass:"taxlogo"},[_c('img',{attrs:{"src":require('@/assets/taxlogo.jpg'),"alt":""}})]):_vm._e(),_c('div',{staticClass:"name"},[_vm._v("企业税务健康测评报告")]),_c('div',{staticClass:"enname"},[_vm._v("CORPORATE TAX HEALTHE VALUATION REPORT")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.date))]),_c('img',{attrs:{"src":require('@/assets/img/lines.png'),"alt":""}})]),_c('div',{staticClass:"reportIntroduction"},[_c('div',{staticClass:"listTitle"},[_c('div',{staticClass:"left",staticStyle:{"background-repeat":"no-repeat"},style:('background-image:url(' +
            require('@/assets/img/index_bg1.png') +
            ')')},[_c('span',{staticClass:"index"},[_vm._v(" 01 ")])]),_vm._m(0)]),_c('div',{staticClass:"info"},[_vm._v(" 本次税务健康评测报告以贵公司"),_c('span',{staticClass:"keyword"},[_vm._v(_vm._s(_vm.excelUses))]),_vm._v("等数据为基础，以数据挖掘技术，全方位专业客观评测企业财务涉税风险状况。 ")])]),_c('div',{staticClass:"basicInformation"},[_c('div',{staticClass:"listTitle"},[_c('div',{staticClass:"left",staticStyle:{"background-repeat":"no-repeat"},style:('background-image:url(' +
            require('@/assets/img/index_bg1.png') +
            ')')},[_c('span',{staticClass:"index"},[_vm._v(" 02 ")])]),_vm._m(1)]),_c('a-table',{staticClass:"table1",attrs:{"columns":_vm.columns1,"data-source":_vm.data1,"rowClassName":(record, index) => (index % 2 === 1 ? 'table-striped' : null),"pagination":false,"bordered":""}}),_c('a-table',{staticClass:"table2",attrs:{"columns":_vm.columns2,"data-source":_vm.data2,"rowClassName":(record, index) => (index % 2 === 1 ? 'table-striped' : null),"pagination":false,"bordered":""}})],1),_c('div',{staticClass:"riskOverall"},[_c('div',{staticClass:"listTitle"},[_c('div',{staticClass:"left",staticStyle:{"background-repeat":"no-repeat"},style:('background-image:url(' +
            require('@/assets/img/index_bg1.png') +
            ')')},[_c('span',{staticClass:"index"},[_vm._v(" 03 ")])]),_vm._m(2)]),_vm._m(3),_c('div',{staticClass:"chart"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"score",style:('background-image:url(' +
              require('@/assets/img/score.png') +
              ')')},[_c('div',{staticClass:"title"},[_vm._v("测评得分")]),_c('div',{staticClass:"value",style:({ color: _vm.getColor(_vm.riskMain.totalScore) })},[_vm._v(" "+_vm._s(_vm.riskMain.totalScore)+" ")])]),_c('div',{staticClass:"level",style:({ color: _vm.getColor(_vm.riskMain.totalScore) })},[_vm._v(" "+_vm._s(_vm.getRiskLevel(_vm.riskMain.totalScore))+" ")]),_c('div',{staticClass:"star"},[_c('img',{style:({ left: _vm.riskMain.totalScore + '%' }),attrs:{"src":require('@/assets/img/star.png'),"alt":""}})]),_c('div',{staticClass:"range",style:('background-image:url(' +
              require('@/assets/img/range.png') +
              ')')}),_vm._m(4)]),_c('div',{staticClass:"right"},[_c('v-chart',{attrs:{"options":_vm.options,"height":"350px"}})],1)])]),_c('div',{staticClass:"indicatorOfHighRisk"},[_c('div',{staticClass:"listTitle"},[_c('div',{staticClass:"left",staticStyle:{"background-repeat":"no-repeat"},style:('background-image:url(' +
            require('@/assets/img/index_bg1.png') +
            ')')},[_c('span',{staticClass:"index"},[_vm._v(" 04 ")])]),_vm._m(5)]),_c('a-table',{staticClass:"table3",attrs:{"columns":_vm.columns3,"data-source":_vm.highrisk.slice(0, 4),"pagination":false,"bordered":""}}),_vm._l((Math.ceil((_vm.highrisk.length - 4) / 5)),function(i){return _c('a-table',{key:i,staticClass:"table31",attrs:{"columns":_vm.columns3,"data-source":_vm.highrisk.slice((i - 1) * 5 + 4, (i - 1) * 5 + 9),"pagination":false,"bordered":""}})})],2),_c('div',{staticClass:"detailIndicatorOfRisk"},[_c('div',{staticClass:"listTitle"},[_c('div',{staticClass:"left",staticStyle:{"background-repeat":"no-repeat"},style:('background-image:url(' +
            require('@/assets/img/index_bg1.png') +
            ')')},[_c('span',{staticClass:"index"},[_vm._v(" 05 ")])]),_vm._m(6)]),_c('a-table',{staticClass:"table4",attrs:{"columns":_vm.columns4,"data-source":_vm.data4.slice(0, 4),"pagination":false,"bordered":""}}),_vm._l((Math.ceil((_vm.data4.length - 4) / 5)),function(i){return _c('a-table',{key:i,staticClass:"table41",attrs:{"columns":_vm.columns4,"data-source":_vm.data4.slice((i - 1) * 5 + 4, (i - 1) * 5 + 9),"pagination":false,"bordered":""}})})],2),_c('div',{staticClass:"responsibilityAndScopeOfUse"},[_c('div',{staticClass:"listTitle"},[_c('div',{staticClass:"left",staticStyle:{"background-repeat":"no-repeat"},style:('background-image:url(' +
            require('@/assets/img/index_bg1.png') +
            ')')},[_c('span',{staticClass:"index"},[_vm._v(" 06 ")])]),_vm._m(7)]),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"name"},[_vm._v("报告说明")]),_c('div',{staticClass:"enname"},[_vm._v("Report Introduction")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"name"},[_vm._v("基本信息")]),_c('div',{staticClass:"enname"},[_vm._v("The Basic Information")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"name"},[_vm._v("涉税风险总体情况")]),_c('div',{staticClass:"enname"},[_vm._v("The Risk Overall")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_vm._v(" 本次税务健康评测报告以贵公司"),_c('span',{staticClass:"keyword"},[_vm._v("申请表、会计报表、税务申报表")]),_vm._v("等数据为基础，以数据挖掘技术，全方位专业客观评测企业财务涉税风险状况。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nums"},[_c('div',{staticStyle:{"width":"60%"}},[_vm._v("0")]),_c('div',{staticStyle:{"width":"20%"}},[_vm._v("60")]),_c('div',{staticStyle:{"width":"10%"}},[_vm._v("80")]),_c('div',{staticStyle:{"width":"10%","text-align":"right"}},[_vm._v("100")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"name"},[_vm._v("涉税高风险指标")]),_c('div',{staticClass:"enname"},[_vm._v("Key Indicators Of Tax-reltated Risks")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"name"},[_vm._v("涉税风险明细指标")]),_c('div',{staticClass:"enname"},[_vm._v("Detail Indicators Of Tax-reltated Risks")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"name"},[_vm._v("使用责任及使用范围")]),_c('div',{staticClass:"enname"},[_vm._v("Responsibility And Scope Of Use")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_c('span',[_vm._v("NO.1")]),_vm._v("本次税务健康评测，遵循相关法律、法规，并恪守独立、客观、公正等原则。 ")]),_c('div',[_c('span',[_vm._v("NO.2")]),_vm._v("数据源由委托方提供，资料的真实性、合法性、完整性及合理使用税务评测报告是委托方的责任。 ")]),_c('div',[_c('span',[_vm._v("NO.3")]),_vm._v("本公司出具的税务健康评测报告中的判断及结论受到评测假设、评测方法、评测限制条件等因素的影响，仅指明了可能存在涉税风险的事项，使用者需结合实际经济业务进行判断，建议尽快对风险作出应对，避免受到经济损失、影响企业声誉。 ")])])
}]

export { render, staticRenderFns }