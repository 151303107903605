<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-13 13:08:26
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-08-17 02:24:39
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\taxRiskControl\riskAnalyse\Table.vue
-->
<template>
  <div>
    <div class="options">
      <a-form layout="inline">
        <a-form-item label="申报时期">
          <a-button-group style="margin-left: 20px">
            <a-button
              :type="dataType == 0 ? 'primary' : ''"
              @click="
                dataType = 0;
                getOwData();
              "
              >年度</a-button
            >
            <a-button
              :type="dataType == 1 ? 'primary' : ''"
              @click="
                dataType = 1;
                getOwData();
              "
              >季度</a-button
            >
            <a-button
              :type="dataType == 2 ? 'primary' : ''"
              @click="
                dataType = 2;
                getOwData();
              "
              v-if="fieldInfo.taxpayerNature != 1"
              >月度</a-button
            >
          </a-button-group>
        </a-form-item>
        <a-form-item label="年份" style="margin-left: 20px">
          <a-select
            v-model="year"
            placeholder="请选择年份"
            style="width: 150px; margin-left: 20px"
            @change="getOwData"
          >
            <a-select-option v-for="item in yearList" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="季度"
          style="margin-left: 20px"
          v-if="dataType == 1"
        >
          <a-select
            v-model="season"
            placeholder="请选择季度"
            style="width: 150px; margin-left: 20px"
            @change="getOwData"
          >
            <!-- <a-select-option :value="1"> 一季度 </a-select-option> -->
            <a-select-option :value="2"> 二季度 </a-select-option>
            <a-select-option :value="3"> 三季度 </a-select-option>
            <a-select-option :value="4"> 四季度 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="月份"
          style="margin-left: 20px"
          v-if="dataType == 2"
        >
          <a-select
            v-model="month"
            placeholder="请选择月份"
            style="width: 150px; margin-left: 20px"
            @change="getOwData"
          >
            <!-- <a-select-option :value="1"> 一月 </a-select-option> -->
            <a-select-option :value="2"> 二月 </a-select-option>
            <a-select-option :value="3"> 三月 </a-select-option>
            <a-select-option :value="4"> 四月 </a-select-option>
            <a-select-option :value="5"> 五月 </a-select-option>
            <a-select-option :value="6"> 六月 </a-select-option>
            <a-select-option :value="7"> 七月 </a-select-option>
            <a-select-option :value="8"> 八月 </a-select-option>
            <a-select-option :value="9"> 九月 </a-select-option>
            <a-select-option :value="10"> 十月 </a-select-option>
            <a-select-option :value="11"> 十一月 </a-select-option>
            <a-select-option :value="12"> 十二月 </a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item style="margin-left: 20px">
          <a-radio-group v-model="type">
            <a-radio :value="1">内部审核表</a-radio>
            <a-radio :value="2">上传审核表</a-radio>
          </a-radio-group>
        </a-form-item> -->
      </a-form>
      <a-space>
        <a-button
          type="primary"
          style="border-radius: 15px"
          @click="handleAnalyse"
          >立即检测</a-button
        >
        <!-- <a-button type="primary" style="border-radius: 15px" @click="showReport"
          >查看报告</a-button
        > -->
        <a
          style="text-decoration: underline"
          href="javascript:;"
          @click="showReport"
          >查看报告</a
        >
        <!-- <a-button type="primary" style="border-radius: 15px" @click="showPdf"
          >下载报告</a-button
        > -->
      </a-space>
    </div>
    <div class="content" v-if="resultVisible">
      <div class="chart">
        <a-row :gutter="20">
          <a-col :span="12">
            <div class="panel">
              <h1>总得分</h1>
              <v-chart :options="options1" height="350px" />
            </div>
          </a-col>
          <a-col :span="12">
            <div class="panel">
              <h1>风险占比</h1>
              <v-chart :options="options" height="350px" />
              <!-- <div class="tip">
                说明：本次检测总计有<span
                  style="font-size: 20px; color: #383838; margin: 0 5px"
                  >{{ total }}</span
                >个指标，结果显示<span
                  style="font-size: 20px; color: #03bb6b; margin: 0 5px"
                  >{{ normal }}</span
                >个指标正常，风险指标<span
                  style="font-size: 20px; color: #ff941a; margin: 0 5px"
                  >{{ unnormal }}</span
                >项。
              </div> -->
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="statistic">
        <h1>风险项</h1>
        <div class="warning">
          <div
            class="cover"
            :style="
              'background-image:url(' + require('@/assets/img/block.png') + ')'
            "
          >
            <div class="content">
              <h1 style="font-size: 18px">
                经过分析相关财税数据，贵司存在以下税务风险提示：
              </h1>
              <ol>
                <li v-for="(val, key) in riskNote" :key="key">
                  {{ val }}
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="count">
          <a-row :gutter="30">
            <a-col :span="6">
              <div class="index">
                <div class="left">
                  <img
                    :src="require('@/assets/img/gaofengxianquyu.png')"
                    alt=""
                  />
                </div>
                <div class="right">
                  <h1>
                    {{ riskMain.highRiskNum }}<span class="unit">个</span>
                  </h1>
                  <div class="name">高风险</div>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="index">
                <div class="left">
                  <img
                    :src="require('@/assets/img/zhongfengxian.png')"
                    alt=""
                  />
                </div>
                <div class="right">
                  <h1>{{ riskMain.midRiskNum }}<span class="unit">个</span></h1>
                  <div class="name">中风险</div>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="index">
                <div class="left">
                  <img :src="require('@/assets/img/difengxian.png')" alt="" />
                </div>
                <div class="right">
                  <h1>{{ riskMain.lowRiskNum }}<span class="unit">个</span></h1>
                  <div class="name">低风险</div>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="index">
                <div class="left">
                  <img
                    :src="require('@/assets/img/xiangduianquan.png')"
                    alt=""
                  />
                </div>
                <div class="right">
                  <h1>{{ riskMain.noRiskNum }}<span class="unit">个</span></h1>
                  <div class="name">无风险</div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <h1
        style="
          font-size: 16px;
          color: #fff;
          margin-bottom: 20px;
          padding-left: 20px;
        "
      >
        风险指标看板
      </h1>
      <div class="table">
        <div class="card-container">
          <a-tabs type="card">
            <a-tab-pane
              v-for="(item, i) in indexDetailNames"
              :key="i"
              :tab="item"
            >
              <a-table
                class="mtable"
                :columns="columns"
                :data-source="indexDetail[item]"
                :pagination="false"
                :rowClassName="
                  (record, index) => (index % 2 === 0 ? 'table-striped' : null)
                "
              >
                <span slot="riskType" slot-scope="text">
                  <a-tag
                    :key="text"
                    :color="
                      text == '4'
                        ? 'cyan'
                        : text == '3'
                        ? 'red'
                        : text == '2'
                        ? 'orange'
                        : text == '1'
                        ? 'blue'
                        : 'green'
                    "
                  >
                    {{ statusFormatter(text) }}
                  </a-tag>
                </span>
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
    <div class="empty" v-else>
      <img :src="require('@/assets/img/empty.png')" alt="" />
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "序号",
    width: "100px",
    align: "center",
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: "指标名称",
    width: "300px",
    dataIndex: "indexName",
    key: "indexName",
    align: "center",
  },
  {
    title: "风险等级",
    width: "150px",
    dataIndex: "riskType",
    key: "riskType",
    align: "center",
    scopedSlots: { customRender: "riskType" },
  },
  {
    title: "风险描述",
    dataIndex: "riskDesc",
    key: "riskDesc",
    align: "center",
  },
];
import { mapGetters } from "vuex";
import { getYear } from "@/api/tax";
import { getOwData, calData } from "@/api/risk";
export default {
  name: "",
  data() {
    return {
      dataType: 0,
      year: 0,
      yearList: [],
      season: 2,
      month: 2,
      type: 2,
      resultVisible: false,
      indexDetailNames: [
        "增值税税收风险",
        "企业所得税税收风险",
        "往来款核算风险",
        "隐瞒收入风险",
        "虚增成本费用风险",
        "其他税费风险",
      ],
      indexDetail: {},
      riskMain: {},
      riskNote: {},
      columns,
      options: {},
      options1: {},
    };
  },
  async created() {
    await this.getYear();
    await this.getOwData();
  },
  methods: {
    async getYear() {
      let params = {
        type: 2,
      };
      let res = await getYear(params);
      if (res.status == 200) {
        let years = res.data;
        this.yearList = years;
        this.year = years[0];
      }
    },
    statusFormatter(status) {
      if (status == 0) {
        return "无风险";
      } else if (status == 1) {
        return "低风险";
      } else if (status == 2) {
        return "中风险";
      } else if (status == 3) {
        return "高风险";
      } else if (status == 4) {
        return "异常指标";
      }
    },
    async handleAnalyse() {
      let params = {
        year: this.year,
        dateType: this.dataType,
        indexStandardType: this.fieldInfo.indexStandardType,
        taxpayerNature: this.fieldInfo.taxpayerNature,
        dateTime:
          this.dataType == 0
            ? this.year
            : this.dataType == 1
            ? this.season
            : this.month,
        dateSource: 0,
      };
      let res = await calData(params);
      if (res.status == 200) {
        this.$message.success("检测完成！");
        await this.getOwData();
        await this.showReport();
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    async getOwData() {
      let params = {
        year: this.year,
        dateType: this.dataType,
        indexStandardType: this.fieldInfo.indexStandardType,
        taxpayerNature: this.fieldInfo.taxpayerNature,
        dateTime:
          this.dataType == 0
            ? this.year
            : this.dataType == 1
            ? this.season
            : this.month,
        dateSource: 0,
      };
      let res = await getOwData(params);
      if (res.status == 200) {
        if (res.data != null) {
          let data = res.data;
          let indexDetail = data.indexDetail;
          let riskMain = data.riskMain;
          let riskNote = data.riskNote;
          this.indexDetail = indexDetail;
          this.riskMain = riskMain;
          this.riskNote = riskNote;
          // this.indexDetailNames = [];
          // for (let i in indexDetail) {
          //   this.indexDetailNames.push(i);
          // }
          this.options = {
            tooltip: {
              trigger: "item",
            },
            color: ["#fc504b", "#ff941a", "#03bb6b", "#0081fc"],
            legend: {
              bottom: "0",
              left: "center",
              textStyle: {
                color: "#ccc",
              },
            },
            series: [
              {
                type: "pie",
                radius: ["40%", "60%"],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderWidth: 2,
                },
                label: {
                  normal: {
                    show: true,
                    formatter: "{d}% ",
                    textStyle: {
                      fontSize: this.standSize / 150,
                      color: "#fff",
                    },
                  },
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: "40",
                    fontWeight: "bold",
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
                labelLine: {
                  normal: {
                    lineStyle: {
                      color: "#fff",
                    },
                    smooth: 0.2,
                    length: 50,
                    length2: 30,
                  },
                },
                data: [
                  { value: riskMain.highRiskNum, name: "高风险" },
                  { value: riskMain.midRiskNum, name: "中风险" },
                  { value: riskMain.lowRiskNum, name: "低分险" },
                  { value: riskMain.noRiskNum, name: "无风险" },
                ],
              },
            ],
          };
          this.options1 = {
            series: [
              {
                type: "gauge",
                center: ["50%", "60%"],
                startAngle: 200,
                endAngle: -20,
                min: 0,
                max: 100,
                splitNumber: 10,
                itemStyle: {
                  color: "#ff941a",
                },
                progress: {
                  show: true,
                  width: 30,
                },
                pointer: {
                  show: false,
                },
                axisLine: {
                  lineStyle: {
                    width: 30,
                  },
                },
                axisTick: {
                  distance: -45,
                  splitNumber: 5,
                  lineStyle: {
                    width: 2,
                    color: "#999",
                  },
                },
                splitLine: {
                  distance: -52,
                  length: 14,
                  lineStyle: {
                    width: 3,
                    color: "#999",
                  },
                },
                axisLabel: {
                  distance: -20,
                  color: "#999",
                  fontSize: 20,
                },
                anchor: {
                  show: false,
                },
                title: {
                  offsetCenter: [0, "-45%"],
                  fontSize: 24,
                  color: "#fff",
                },
                detail: {
                  valueAnimation: true,
                  width: "60%",
                  lineHeight: 40,
                  borderRadius: 8,
                  offsetCenter: [10, "0%"],
                  fontSize: 40,
                  fontWeight: "bolder",
                  formatter: "{value} ",
                  color: "auto",
                },
                data: [
                  {
                    name: "得分",
                    value: riskMain.totalScore,
                    color: "#fff",
                  },
                ],
              },
            ],
          };
          this.resultVisible = true;
        } else {
          this.resultVisible = false;
        }
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    showReport() {
      if (this.resultVisible) {
        let params = {
          year: this.year,
          dateType: this.dataType,
          indexStandardType: this.fieldInfo.indexStandardType,
          taxpayerNature: this.fieldInfo.taxpayerNature,
          dateTime:
            this.dataType == 0
              ? this.year
              : this.dataType == 1
              ? this.season
              : this.month,
          dateSource: 0,
        };
        let routeUrl = this.$router.resolve({
          name: "riskReport",
          query: params,
        });
        this.openurl(routeUrl.href);
      } else {
        this.$notification.warning({
          message: "系统提示",
          description: "当前时期尚未进行检测！",
          duration: 4,
        });
      }
    },
    showPdf() {
      let params = {
        year: this.year,
        dateType: this.dataType,
        indexStandardType: this.fieldInfo.indexStandardType,
        taxpayerNature: this.fieldInfo.taxpayerNature,
        dateTime:
          this.dataType == 0
            ? this.year
            : this.dataType == 1
            ? this.season
            : this.month,
        dateSource: 0,
      };
      let routeUrl = this.$router.resolve({
        name: "riskPdf",
        query: params,
      });
      this.openurl(routeUrl.href);
    },
    openurl(url) {
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("style", "display:none");
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    },
  },
  computed: {
    total() {
      let riskMain = this.riskMain;
      if (JSON.stringify(riskMain) != "{}") {
        return (
          riskMain.highRiskNum +
          riskMain.midRiskNum +
          riskMain.lowRiskNum +
          riskMain.noRiskNum
        );
      }
      return "";
    },
    normal() {
      let riskMain = this.riskMain;
      if (JSON.stringify(riskMain) != "{}") {
        return riskMain.lowRiskNum + riskMain.noRiskNum;
      }
      return "";
    },
    unnormal() {
      let riskMain = this.riskMain;
      if (JSON.stringify(riskMain) != "{}") {
        return riskMain.highRiskNum + riskMain.midRiskNum;
      }
      return "";
    },
    ...mapGetters(["fieldInfo"]),
  },
};
</script>
<style lang="less" scoped>
.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-bottom: 20px;
  padding: 20px;
  background: #272d4b;
  /deep/ .ant-form-item-label > label {
    color: #fff;
  }
  .ant-btn {
    color: #fff;
    background: #2d334f;
    border: none;
  }
  .ant-btn-primary {
    color: #fff;
    background: #283a6c;
    border: none;
  }
  /deep/ .ant-select-selection {
    color: #fff;
    background: #283a6c;
    border: none;
  }
  .ant-radio-wrapper {
    color: #fff;
  }
}
.statistic {
  color: #fff;
  background: #272d4b;
  padding: 20px;
  margin-bottom: 20px;
  h1 {
    font-size: 16px;
    color: #fff;
  }
  .warning {
    display: flex;
    justify-content: center;
    align-items: center;
    .cover {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-width: 852px;
      height: 240px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      .content {
        width: 1247px;
        height: 240px;
        padding: 60px 50px;
        ol {
          margin-top: 20px;
          padding-left: 15px;
          li::marker {
            content: "●  ";
            color: #3db6cb;
          }
        }
      }
    }
  }
  .count {
    margin-top: 20px;
    .index {
      background: #202441;
      border-radius: 15px;
      padding: 20px 40px;
      overflow: hidden;
      .left,
      .right {
        float: left;
      }
      .left {
        margin-right: 40px;
      }
      .right {
        h1 {
          font-size: 36px;
          font-weight: 900;
          font-family: PingFangSC-Semibold;
          .unit {
            font-size: 18px;
            font-weight: 300;
            vertical-align: 3px;
            margin-left: 5px;
          }
        }
        .name {
          color: #9ea2b5;
        }
      }
    }
  }
}
.chart {
  margin-bottom: 20px;
  .panel {
    height: 453px;
    background: #272d4b !important;
    padding: 20px;
    h1 {
      font-size: 16px;
      color: #fff;
    }
    .tip {
      text-align: center;
      font-size: 16px;
      color: #fff;
    }
  }
}
.table {
  .ant-btn-primary {
    color: #fff;
    background: #283a6c;
    border: none;
  }
  /deep/ .mtable .table-striped td {
    background-color: #202441 !important;
  }
  .card-container {
    background: #202441;
    overflow: hidden;
    /deep/ .ant-tabs-card > .ant-tabs-bar {
      color: #fff;
      border-color: transparent;
      background: transparent;
      .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
      }
    }
    /deep/ .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
      background: #272d4b !important;
      border-color: #272d4b !important;
    }
    /deep/ .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
      background: #272d4b !important;
    }
  }
  /deep/ .card-container > .ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;
  }
  /deep/
    .card-container
    > .ant-tabs-card
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 16px;
  }
  /deep/ .ant-table-thead > tr > th {
    color: #fff;
    background: #272d4b !important;
    border-color: #272d4b !important;
  }
  /deep/ .ant-table-tbody > tr > td {
    color: #fff;
    background: #272d4b !important;
    border-color: #272d4b !important;
  }
  /deep/ .ant-table-placeholder {
    background: #272d4b !important;
    border-color: #272d4b !important;
  }
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 800px;
  background: #272d4b;
}
</style>
