<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-28 10:04:38
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-08-17 02:08:29
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\taxRiskControl\riskAnalyse\RiskPdf.vue
-->
<template>
  <div class="container">
    <div class="cover" id="report">
      <div class="homePage">
        <div class="taxlogo" v-if="$route.query.token">
          <img :src="require('@/assets/taxlogo.jpg')" alt="" />
        </div>
        <div class="name">企业税务健康测评报告</div>
        <div class="enname">CORPORATE TAX HEALTHE VALUATION REPORT</div>
        <div class="date">{{ date }}</div>
        <img :src="require('@/assets/img/lines.png')" alt="" />
      </div>
      <div class="reportIntroduction">
        <div class="listTitle">
          <div
            class="left"
            :style="
              'background-image:url(' +
              require('@/assets/img/index_bg1.png') +
              ')'
            "
            style="background-repeat: no-repeat"
          >
            <span class="index"> 01 </span>
          </div>
          <div class="right">
            <div class="name">报告说明</div>
            <div class="enname">Report Introduction</div>
          </div>
        </div>
        <div class="info">
          本次税务健康评测报告以贵公司<span class="keyword">{{
            excelUses
          }}</span
          >等数据为基础，以数据挖掘技术，全方位专业客观评测企业财务涉税风险状况。
        </div>
      </div>
      <div class="basicInformation">
        <div class="listTitle">
          <div
            class="left"
            :style="
              'background-image:url(' +
              require('@/assets/img/index_bg1.png') +
              ')'
            "
            style="background-repeat: no-repeat"
          >
            <span class="index"> 02 </span>
          </div>
          <div class="right">
            <div class="name">基本信息</div>
            <div class="enname">The Basic Information</div>
          </div>
        </div>
        <a-table
          class="table1"
          :columns="columns1"
          :data-source="data1"
          :rowClassName="
            (record, index) => (index % 2 === 1 ? 'table-striped' : null)
          "
          :pagination="false"
          bordered
        >
        </a-table>
        <a-table
          class="table2"
          :columns="columns2"
          :data-source="data2"
          :rowClassName="
            (record, index) => (index % 2 === 1 ? 'table-striped' : null)
          "
          :pagination="false"
          bordered
        >
        </a-table>
      </div>
      <div class="riskOverall">
        <div class="listTitle">
          <div
            class="left"
            :style="
              'background-image:url(' +
              require('@/assets/img/index_bg1.png') +
              ')'
            "
            style="background-repeat: no-repeat"
          >
            <span class="index"> 03 </span>
          </div>
          <div class="right">
            <div class="name">涉税风险总体情况</div>
            <div class="enname">The Risk Overall</div>
          </div>
        </div>
        <div class="info">
          本次税务健康评测报告以贵公司<span class="keyword"
            >申请表、会计报表、税务申报表</span
          >等数据为基础，以数据挖掘技术，全方位专业客观评测企业财务涉税风险状况。
        </div>
        <div class="chart">
          <div class="left">
            <div
              class="score"
              :style="
                'background-image:url(' +
                require('@/assets/img/score.png') +
                ')'
              "
            >
              <div class="title">测评得分</div>
              <div
                class="value"
                :style="{ color: getColor(riskMain.totalScore) }"
              >
                {{ riskMain.totalScore }}
              </div>
            </div>
            <div
              class="level"
              :style="{ color: getColor(riskMain.totalScore) }"
            >
              {{ getRiskLevel(riskMain.totalScore) }}
            </div>
            <div class="star">
              <img
                :style="{ left: riskMain.totalScore + '%' }"
                :src="require('@/assets/img/star.png')"
                alt=""
              />
            </div>
            <div
              class="range"
              :style="
                'background-image:url(' +
                require('@/assets/img/range.png') +
                ')'
              "
            ></div>
            <div class="nums">
              <div style="width: 60%">0</div>
              <div style="width: 20%">60</div>
              <div style="width: 10%">80</div>
              <div style="width: 10%; text-align: right">100</div>
            </div>
          </div>
          <div class="right">
            <v-chart :options="options" height="350px" />
          </div>
        </div>
      </div>
      <div class="indicatorOfHighRisk">
        <div class="listTitle">
          <div
            class="left"
            :style="
              'background-image:url(' +
              require('@/assets/img/index_bg1.png') +
              ')'
            "
            style="background-repeat: no-repeat"
          >
            <span class="index"> 04 </span>
          </div>
          <div class="right">
            <div class="name">涉税高风险指标</div>
            <div class="enname">Key Indicators Of Tax-reltated Risks</div>
          </div>
        </div>
        <a-table
          class="table3"
          :columns="columns3"
          :data-source="highrisk.slice(0, 4)"
          :pagination="false"
          bordered
        >
        </a-table>
        <a-table
          class="table31"
          :columns="columns3"
          :data-source="highrisk.slice((i - 1) * 5 + 4, (i - 1) * 5 + 9)"
          :pagination="false"
          bordered
          :key="i"
          v-for="i in Math.ceil((highrisk.length - 4) / 5)"
        >
        </a-table>
      </div>
      <div class="detailIndicatorOfRisk">
        <div class="listTitle">
          <div
            class="left"
            :style="
              'background-image:url(' +
              require('@/assets/img/index_bg1.png') +
              ')'
            "
            style="background-repeat: no-repeat"
          >
            <span class="index"> 05 </span>
          </div>
          <div class="right">
            <div class="name">涉税风险明细指标</div>
            <div class="enname">Detail Indicators Of Tax-reltated Risks</div>
          </div>
        </div>
        <a-table
          class="table4"
          :columns="columns4"
          :data-source="data4.slice(0, 4)"
          :pagination="false"
          bordered
        >
        </a-table>
        <a-table
          class="table41"
          :columns="columns4"
          :data-source="data4.slice((i - 1) * 5 + 4, (i - 1) * 5 + 9)"
          :pagination="false"
          bordered
          :key="i"
          v-for="i in Math.ceil((data4.length - 4) / 5)"
        >
        </a-table>
      </div>
      <div class="responsibilityAndScopeOfUse">
        <div class="listTitle">
          <div
            class="left"
            :style="
              'background-image:url(' +
              require('@/assets/img/index_bg1.png') +
              ')'
            "
            style="background-repeat: no-repeat"
          >
            <span class="index"> 06 </span>
          </div>
          <div class="right">
            <div class="name">使用责任及使用范围</div>
            <div class="enname">Responsibility And Scope Of Use</div>
          </div>
        </div>
        <div class="content">
          <div>
            <span>NO.1</span
            >本次税务健康评测，遵循相关法律、法规，并恪守独立、客观、公正等原则。
          </div>
          <div>
            <span>NO.2</span
            >数据源由委托方提供，资料的真实性、合法性、完整性及合理使用税务评测报告是委托方的责任。
          </div>
          <div>
            <span>NO.3</span
            >本公司出具的税务健康评测报告中的判断及结论受到评测假设、评测方法、评测限制条件等因素的影响，仅指明了可能存在涉税风险的事项，使用者需结合实际经济业务进行判断，建议尽快对风险作出应对，避免受到经济损失、影响企业声誉。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getPdfData } from "@/api/risk";
import {
  querySystemTenantidExtendList,
  queryExtendFieldServiceList,
} from "@/api/tenant";
import {
  indexStandardType,
  taxpayerNature,
  industry,
  registrationType,
} from "@/utils/dict";
import pick from "lodash.pick";
export default {
  name: "",
  data() {
    return {
      businessResult: [],
      excelUses: "",
      highrisk: [],
      riskCalResult: [],
      riskMain: {},
      options: {},
      columns1: [
        {
          title: "企业基本信息",
          dataIndex: "info",
          key: "info",
          width: "50%",
          colSpan: 2,
          align: "left",
        },
        {
          title: "企业基本信息",
          dataIndex: "info1",
          key: "info1",
          width: "50%",
          colSpan: 0,
        },
      ],
      data1: [],
      columns2: [
        {
          title: "企业经营信息",
          dataIndex: "info",
          key: "info",
          width: "50%",
          colSpan: 2,
          align: "left",
        },
        {
          title: "企业基本信息",
          dataIndex: "info1",
          key: "info1",
          width: "50%",
          colSpan: 0,
        },
      ],
      data2: [],
      columns3: [
        {
          title: "指标名称",
          dataIndex: "indexName",
          key: "indexName",
          width: "30%",
        },
        {
          title: "风险描述",
          dataIndex: "riskDesc",
          key: "riskDesc",
          width: "50%",
        },
      ],
      columns4: [
        {
          title: "指标名称",
          dataIndex: "indexName",
          key: "indexName",
          width: "30%",
        },
        {
          title: "风险描述",
          dataIndex: "riskDesc",
          key: "riskDesc",
          width: "50%",
        },
      ],
      data4: [],
    };
  },
  async mounted() {
    let flag = this.$moment().isAfter(this.$moment("20990815"));
    // let flag = false;
    if (!flag) {
      await this.getPdfData(
        pick(
          this.$route.query,
          "dateSource",
          "dateTime",
          "dateType",
          "indexStandardType",
          "taxpayerNature",
          "year"
        )
      );
      if (this.$route.query.token) {
        await this.getFieldInfo(this.$route.query.tenantid);
        this.export();
      } else {
        this.data1 = [
          {
            info: "企业名称：" + this.fieldInfo.companyName,
            info1:
              "统一社会信用代码（纳税人识别号）：" + this.fieldInfo.taxpayerNo,
          },
          {
            info:
              "注册类型：" +
              (registrationType.filter(
                (item) => item.value == this.fieldInfo.registrationType
              ).length != 0
                ? registrationType.filter(
                    (item) => item.value == this.fieldInfo.registrationType
                  )[0].label
                : "-"),
            info1:
              "所属行业：" +
              (industry.filter((item) => item.value == this.fieldInfo.industry)
                .length != 0
                ? industry.filter(
                    (item) => item.value == this.fieldInfo.industry
                  )[0].label
                : "-"),
          },
          {
            info:
              "纳税人身份类型：" +
              (taxpayerNature.filter(
                (item) => item.value == this.fieldInfo.taxpayerNature
              ).length != 0
                ? taxpayerNature.filter(
                    (item) => item.value == this.fieldInfo.taxpayerNature
                  )[0].label
                : "-"),
            info1:
              "采用的财务报表格式：" +
              (indexStandardType.filter(
                (item) => item.value == this.fieldInfo.indexStandardType
              ).length != 0
                ? indexStandardType.filter(
                    (item) => item.value == this.fieldInfo.indexStandardType
                  )[0].label
                : "-"),
          },
        ];
      }
    }
  },
  methods: {
    async getFieldInfo(tenantid) {
      await querySystemTenantidExtendList({
        tenantId: tenantid,
        page: 1,
        pageSize: 20,
      }).then(async (response) => {
        if (response.status == "200") {
          await queryExtendFieldServiceList({
            tenantid: tenantid,
            page: 1,
            pageSize: 20,
          }).then((response1) => {
            if (response1.status == "200") {
              const dicts = response1.data.rows;
              const result = response.data;
              let rows = result.rows;
              let fieldInfo = {};
              rows.forEach((row) => {
                let key = dicts.filter(
                  (item) => item.id == row.expandFieldId
                )[0].expandFieldName;
                fieldInfo[key] = row.expandFieldValue;
              });
              this.data1 = [
                {
                  info: "企业名称：" + fieldInfo.companyName,
                  info1:
                    "统一社会信用代码（纳税人识别号）：" + fieldInfo.taxpayerNo,
                },
                {
                  info:
                    "注册类型：" +
                    (registrationType.filter(
                      (item) => item.value == fieldInfo.registrationType
                    ).length != 0
                      ? registrationType.filter(
                          (item) => item.value == fieldInfo.registrationType
                        )[0].label
                      : "-"),
                  info1:
                    "所属行业：" +
                    (industry.filter((item) => item.value == fieldInfo.industry)
                      .length != 0
                      ? industry.filter(
                          (item) => item.value == fieldInfo.industry
                        )[0].label
                      : "-"),
                },
                {
                  info:
                    "纳税人身份类型：" +
                    (taxpayerNature.filter(
                      (item) => item.value == fieldInfo.taxpayerNature
                    ).length != 0
                      ? taxpayerNature.filter(
                          (item) => item.value == fieldInfo.taxpayerNature
                        )[0].label
                      : "-"),
                  info1:
                    "采用的财务报表格式：" +
                    (indexStandardType.filter(
                      (item) => item.value == fieldInfo.indexStandardType
                    ).length != 0
                      ? indexStandardType.filter(
                          (item) => item.value == fieldInfo.indexStandardType
                        )[0].label
                      : "-"),
                },
              ];
            } else {
              let msg = response1.msg || response1.message;
              let len = msg.length;
              this.$notification.error({
                message: "系统提示",
                description: len > 100 ? "系统错误，请联系管理员" : msg,
                duration: 4,
              });
            }
          });
        } else {
          let msg = response.msg || response.message;
          let len = msg.length;
          this.$notification.error({
            message: "系统提示",
            description: len > 100 ? "系统错误，请联系管理员" : msg,
            duration: 4,
          });
        }
      });
    },
    async getPdfData(params) {
      let res = await getPdfData(params);
      if (res.status == 200) {
        this.businessResult = res.data.businessResult;
        this.excelUses = res.data.excelUses.excelName;
        // this.highrisk = res.data.highrisk;
        this.highrisk = res.data.highrisk;
        let riskCalResult = res.data.riskCalResult;
        for (let i in riskCalResult) {
          let item = riskCalResult[i];
          for (let n = 0; n < item.length; n++) {
            this.riskCalResult.push(item[n]);
          }
        }
        this.data4 = this.riskCalResult;
        this.riskMain = res.data.riskMain;
        if (this.businessResult) {
          this.setBusiInfo();
        }
        this.options = {
          animation: false,
          tooltip: {
            trigger: "item",
          },
          color: ["#fc504b", "#ff941a", "#03bb6b", "#0081fc"],
          legend: {
            bottom: "0",
            left: "center",
            textStyle: {
              color: "#666",
            },
          },
          series: [
            {
              type: "pie",
              center: ["47%", "40%"],
              radius: ["40%", "60%"],
              avoidLabelOverlap: false,
              itemStyle: {
                borderWidth: 2,
              },
              label: {
                normal: {
                  show: true,
                  formatter: "{c}项",
                  textStyle: {
                    fontSize: this.standSize / 150,
                    color: "#666",
                  },
                },
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "40",
                  fontWeight: "bold",
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              labelLine: {
                normal: {
                  lineStyle: {
                    color: "#555",
                  },
                  smooth: 0.2,
                  length: 20,
                  length2: 10,
                },
              },
              data: [
                { value: this.riskMain.highRiskNum, name: "高风险" },
                { value: this.riskMain.midRiskNum, name: "中风险" },
                { value: this.riskMain.lowRiskNum, name: "低分险" },
                { value: this.riskMain.noRiskNum, name: "无风险" },
              ],
            },
          ],
        };
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    setBusiInfo() {
      let key1 = this.businessResult.filter(
        (item) => item.indexName == "资产总额"
      );
      let val1 = key1.length != 0 ? key1[0].indexVal || "" : "";
      let key2 = this.businessResult.filter(
        (item) => item.indexName == "营业收入"
      );
      let val2 = key2.length != 0 ? key2[0].indexVal || "" : "";
      let key3 = this.businessResult.filter(
        (item) => item.indexName == "负债总额"
      );
      let val3 = key3.length != 0 ? key3[0].indexVal || "" : "";
      let key4 = this.businessResult.filter(
        (item) => item.indexName == "营业成本"
      );
      let val4 = key4.length != 0 ? key4[0].indexVal || "" : "";
      let key5 = this.businessResult.filter(
        (item) => item.indexName == "权益总额"
      );
      let val5 = key5.length != 0 ? key5[0].indexVal || "" : "";
      let key6 = this.businessResult.filter(
        (item) => item.indexName == "管理费用"
      );
      let val6 = key6.length != 0 ? key6[0].indexVal || "" : "";
      let key7 = this.businessResult.filter(
        (item) => item.indexName == "总资产周转率"
      );
      let val7 = key7.length != 0 ? key7[0].indexVal || "" : "";
      let key8 = this.businessResult.filter(
        (item) => item.indexName == "利润总额"
      );
      let val8 = key8.length != 0 ? key8[0].indexVal || "" : "";
      let key9 = this.businessResult.filter(
        (item) => item.indexName == "资产负债率"
      );
      let val9 = key9.length != 0 ? key9[0].indexVal || "" : "";
      let key10 = this.businessResult.filter(
        (item) => item.indexName == "毛利率"
      );
      let val10 = key10.length != 0 ? key10[0].indexVal || "" : "";
      let key11 = this.businessResult.filter(
        (item) => item.indexName == "增值税税负率"
      );
      let val11 = key11.length != 0 ? key11[0].indexVal || "" : "";
      let key12 = this.businessResult.filter(
        (item) => item.indexName == "企业所得税税负率"
      );
      let val12 = key12.length != 0 ? key12[0].indexVal || "" : "";
      this.data2 = [
        {
          info: "资产总额：" + val1,
          info1: "营业收入：" + val2,
        },
        {
          info: "负债总额：" + val3,
          info1: "营业成本：" + val4,
        },
        {
          info: "权益总额：" + val5,
          info1: "管理费用：" + val6,
        },
        {
          info: "总资产周转率：" + val7,
          info1: "利润总额：" + val8,
        },
        {
          info: "资产负债率：" + val9,
          info1: "毛利率：" + val10,
        },
        {
          info: "增值税税负率：" + val11,
          info1: "企业所得税税负率：" + val12,
        },
      ];
    },
    export() {
      this.exportPdfHandler(
        "report",
        "企业税务健康测评报告" + this.$moment().format("YYYYMMDDHHmmss")
      );
    },
    getRiskLevel(val) {
      let label = "";
      if (val < 60) {
        label = "高风险";
      } else if (val < 80) {
        label = "中风险";
      } else if (val < 99) {
        label = "低风险";
      } else {
        label = "无风险";
      }
      return label;
    },
    getColor(val) {
      let label = "";
      if (val < 60) {
        label = "#fd3b53";
      } else if (val < 80) {
        label = "#f2ff00";
      } else if (val < 99) {
        label = "#01ffd1";
      } else {
        label = "#7ed321";
      }
      return label;
    },
  },
  computed: {
    ...mapGetters(["fieldInfo"]),
    date() {
      let query = this.$route.query;
      if (query.dateType == 0) {
        return query.year + "年";
      } else if (query.dateType == 1) {
        let season =
          query.dateTime == 1
            ? "一"
            : query.dateTime == 2
            ? "二"
            : query.dateTime == 3
            ? "三"
            : "四";
        return query.year + "年" + season + "季度";
      } else {
        return query.year + "年" + query.dateTime + "月";
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .table1 .table-striped td {
  background-color: #f8f6ed;
}
/deep/ .table1 .ant-table-thead > tr > th {
  color: #fff !important;
  background: #b5b271 !important;
  padding: 10px 16px !important;
}
/deep/ .table1 .ant-table-row-cell-break-word {
  padding: 10px 16px !important;
}
/deep/ .table2 .table-striped td {
  background-color: #fff7eb;
}
/deep/ .table2 .ant-table-thead > tr > th {
  color: #fff !important;
  background: #e8a165 !important;
  padding: 10px 16px !important;
}
/deep/ .table2 .ant-table-row-cell-break-word {
  padding: 10px 16px !important;
}
/deep/ .table3 .table-striped td {
  background-color: #fff7eb;
}
/deep/ .table3 .ant-table-thead > tr > th {
  color: #fff !important;
  background: #e8a165 !important;
  padding: 10px 16px !important;
}
/deep/ .table3 .ant-table-row-cell-break-word {
  padding: 10px 16px !important;
}
/deep/ .table4 .table-striped td {
  background-color: #fff7eb;
}
/deep/ .table4 .ant-table-thead > tr > th {
  color: #fff !important;
  background: #71cac2 !important;
  padding: 10px 16px !important;
}
/deep/ .table4 .ant-table-row-cell-break-word {
  padding: 10px 16px !important;
}
// /deep/ .table41 .table-striped td {
//   background-color: #fff7eb;
// }
// /deep/ .table41 .ant-table-thead > tr > th {
//   color: #fff !important;
//   background: #71cac2 !important;
//   padding: 10px 16px !important;
// }
// /deep/ .table41 .ant-table-row-cell-break-word {
//   padding: 10px 16px !important;
// }
/deep/ .table31 .ant-table-thead {
  display: none;
}
/deep/ .table41 .ant-table-thead {
  display: none;
}
/deep/ .table3 .ant-table-tbody > tr > td {
  height: 180px;
}
/deep/ .table4 .ant-table-tbody > tr > td {
  height: 180px;
}
/deep/ .table31 .ant-table-tbody > tr > td {
  height: 180px;
}
/deep/ .table41 .ant-table-tbody > tr > td {
  height: 180px;
}
.table31 {
  margin-top: 58px;
  height: 901px;
}
.table41 {
  margin-top: 58px;
  height: 901px;
}
.container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  .cover {
    width: 675px;
    margin: 0 auto;
    padding-top: 108px;
    .homePage {
      position: relative;
      .taxlogo {
        position: absolute;
        top: -80px;
        left: 30px;
        width: 200px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 80px;
        font-weight: 900;
        color: #009245;
        padding: 0 80px;
      }
      .enname {
        font-size: 38px;
        color: #8cc63f;
        padding: 0 80px;
      }
      .date {
        font-size: 24px;
        color: #009245;
        margin-top: 18px;
        padding: 0 80px;
      }
    }
    .reportIntroduction {
      padding: 0 50px;
      .info {
        font-size: 14px;
        font-weight: 900;
        text-indent: 30px;
        line-height: 30px;
        background: #fbfce8;
        margin-top: 20px;
        padding: 10px 20px;
        .keyword {
          color: #f3703f;
        }
      }
    }
    .basicInformation {
      padding: 0 50px;
      .info {
        font-size: 14px;
        font-weight: 900;
        text-indent: 30px;
        line-height: 30px;
        margin-top: 20px;
        padding: 10px 20px;
        .keyword {
          color: #f3703f;
        }
      }
      .table1 {
        margin-top: 35px;
        margin-bottom: 30px;
      }
    }
    .riskOverall {
      margin-top: 70px;
      padding: 0 50px;
      .info {
        font-size: 14px;
        font-weight: 900;
        text-indent: 30px;
        line-height: 30px;
        margin-top: 20px;
        padding: 10px 20px;
        .keyword {
          color: #f3703f;
        }
      }
      .chart {
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 50%;
          .score {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 232px;
            height: 212px;
            .title {
              font-size: 20px;
            }
            .value {
              font-size: 60px;
              font-weight: 700;
              line-height: 70px;
              color: #f7931e;
            }
          }
          .level {
            font-size: 22px;
          }
          .star {
            width: 203px;
            height: 20px;
            img {
              position: relative;
            }
          }
          .range {
            width: 203px;
            height: 7px;
            margin-top: 5px;
          }
          .nums {
            display: flex;
            justify-content: space-between;
            width: 203px;
          }
        }
        .right {
          width: 50%;
        }
      }
    }
    .indicatorOfHighRisk {
      margin-top: 380px;
      padding: 0 50px;
      .table3 {
        margin: 30px 0;
        margin-bottom: 62px;
        height: 763px;
      }
    }
    .detailIndicatorOfRisk {
      margin-top: 32px;
      padding: 0 50px;
      .table4 {
        margin: 30px 0;
        margin-bottom: 75px;
        height: 763px;
      }
    }
    .responsibilityAndScopeOfUse {
      margin-top: 50px;
      padding: 0 50px;
      .content {
        font-weight: 700;
        margin-top: 20px;
        padding: 10px 20px;
        background: #fbfce8;
        & > div {
          line-height: 24px;
        }
        span {
          color: #f3703f;
          margin-right: 15px;
        }
      }
    }
    .listTitle {
      display: flex;
      justify-content: flex-start;
      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 114px;
        height: 120px;
        background-position: bottom;
        .index {
          font-size: 70px;
          text-align: center;
          color: #666;
        }
      }
      .right {
        width: 100%;
        border-bottom: 1px solid #b9de9c;
        margin-left: 20px;
        padding-top: 30px;
        .name {
          font-size: 34px;
          color: #666;
        }
        .enname {
          font-size: 16px;
          color: #666;
        }
      }
    }
  }
}
</style>