<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-29 11:26:23
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-12 20:37:41
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\taxRiskControl\dataCollect\Empty.vue
-->
<template>
  <div class="cover">
    <img :src="require('@/assets/img/empty.png')" alt="" />
  </div>
</template>
<script>
export default {
  name: "",
};
</script>
<style lang="less" scoped>
.cover {
  background: #272d4b;
  padding: 100px 0;
}
</style>