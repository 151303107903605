<!--
 * @Author: zhangjingqing
 * @Date: 2022-05-29 19:49:04
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-08-18 21:12:22
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\user\RegisterModal.vue
-->
<template>
  <a-modal
    title="账号注册"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    okText="注册"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-descriptions title="基本信息" :column="3"> </a-descriptions>
      <a-form-item label="账号">
        <a-input
          v-decorator="[
            'contactEmail',
            {
              rules: [{ validator: this.handleAccount }],
              trigger: 'blur',
            },
          ]"
          placeholder="请输入邮箱"
        />
      </a-form-item>
      <a-popover
        placement="rightTop"
        trigger="click"
        :visible="state.passwordLevelChecked"
      >
        <template slot="content">
          <div :style="{ width: '240px' }">
            <div :class="['user-register', passwordLevelClass]">
              强度：<span>{{ passwordLevelName }}</span>
            </div>
            <a-progress
              :percent="state.percent"
              :showInfo="false"
              :strokeColor="passwordLevelColor"
            />
            <div style="margin-top: 10px">
              <span>请至少输入 8 个字符。请不要使用容易被猜到的密码。</span>
            </div>
          </div>
        </template>
        <a-form-item label="密码">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [{ validator: this.handlePasswordLevel }],
                trigger: 'blur',
              },
            ]"
            type="password"
            @focus="handlePasswordInputClick(true)"
            @blur="handlePasswordInputClick(false)"
            autocomplete="new-password"
            placeholder="至少8位密码，区分大小写"
          ></a-input>
        </a-form-item>
      </a-popover>

      <a-form-item label="确认密码">
        <a-input
          v-decorator="[
            'password2',
            {
              rules: [
                { required: true, message: '确认密码不能为空' },
                { validator: this.handlePasswordCheck },
              ],
              trigger: 'blur',
            },
          ]"
          type="password"
          autocomplete="false"
          placeholder="确认密码"
        ></a-input>
      </a-form-item>
      <a-form-item label="激活码" v-if="isU8c">
        <a-input
          v-decorator="[
            'cdk',
            {
              rules: [{ required: true, message: '激活码不能为空' }],
              trigger: 'blur',
            },
          ]"
          placeholder="请输入激活码"
        />
      </a-form-item>
      <a-descriptions title="企业信息" :column="3"> </a-descriptions>
      <a-form-item label="企业名称">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: '企业名称不能为空' }],
              trigger: 'blur',
            },
          ]"
          placeholder="请输入企业名称"
        />
      </a-form-item>
      <a-form-item label="联系人">
        <a-input
          v-decorator="[
            'contactPerson',
            {
              rules: [{ required: true, message: '联系人不能为空' }],
              trigger: 'blur',
            },
          ]"
          placeholder="请输入联系人"
        />
      </a-form-item>
      <a-form-item label="联系电话">
        <a-input
          v-decorator="[
            'contactMobile',
            {
              rules: [{ required: true, message: '联系电话不能为空' }],
              trigger: 'blur',
            },
          ]"
          placeholder="请输入手机号"
        />
      </a-form-item>
      <a-form-item label="纳税人识别号">
        <a-input
          v-decorator="[
            'taxpayerNo',
            {
              rules: [
                {
                  required: true,
                  message: '纳税人识别号不能为空',
                },
              ],
              trigger: 'blur',
            },
          ]"
          placeholder="请输入纳税人识别号"
        />
      </a-form-item>
      <a-form-item label="纳税人性质">
        <a-select
          v-decorator="[
            'taxpayerNature',
            {
              rules: [{ required: true, message: '纳税人性质不能为空' }],
            },
          ]"
          placeholder="请选择纳税人性质"
        >
          <a-select-option
            v-for="item in taxpayerNature"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="会计准则类型">
        <a-select
          v-decorator="[
            'indexStandardType',
            {
              rules: [{ required: true, message: '会计准则类型不能为空' }],
            },
          ]"
          placeholder="请选择会计准则类型"
        >
          <a-select-option
            v-for="item in indexStandardType"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="注册类型">
        <a-select
          v-decorator="[
            'registrationType',
            {
              rules: [{ required: true, message: '注册类型不能为空' }],
            },
          ]"
          placeholder="请选择注册类型"
        >
          <a-select-option
            v-for="item in registrationType"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属行业">
        <a-select
          v-decorator="[
            'industry',
            {
              rules: [{ required: true, message: '所属行业不能为空' }],
            },
          ]"
          placeholder="请选择所属行业"
        >
          <a-select-option
            v-for="item in industry"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- <a-form-item label="成立时间">
        <a-date-picker
          style="width: 100%"
          format="YYYY"
          mode="year"
          v-decorator="[
            'since',
            {
              rules: [{ required: true, message: '成立时间不能为空' }],
              trigger: 'blur',
            },
          ]"
          placeholder="请选择成立时间"
          :open="open"
          @openChange="openChange"
          @panelChange="panelChange"
        />
      </a-form-item> -->
    </a-form>
  </a-modal>
</template>
<script>
const levelNames = {
  0: "低",
  1: "低",
  2: "中",
  3: "强",
};
const levelClass = {
  0: "error",
  1: "error",
  2: "warning",
  3: "success",
};
const levelColor = {
  0: "#ff0000",
  1: "#ff0000",
  2: "#ff7e05",
  3: "#52c41a",
};
import pick from "lodash.pick";
import { registerSystemTenantid, registeru8, registeru8c } from "@/api/login";
import {
  queryExtendFieldServiceList,
  createSystemTenantidExtendBatch,
} from "@/api/tenant";
import {
  indexStandardType,
  taxpayerNature,
  industry,
  registrationType,
} from "@/utils/dict";
import { getQueryString } from "@/utils/utils";
export default {
  name: "RegisterModal",
  data() {
    return {
      indexStandardType,
      taxpayerNature,
      industry,
      registrationType,
      visible: false,
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
      open: false,
      state: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: "#FF0000",
      },
      expandFields: [],
      isU8: false,
      isU8c: false,
    };
  },
  methods: {
    async show() {
      // this.isU8 = location.host == "test-u8.hthuiyou.com";
      this.isU8 = location.host == "tax-u8.hthuiyou.com";
      // this.isU8c = location.host == "test-u8c.hthuiyou.com";
      this.isU8c = location.host == "tax-u8c.hthuiyou.com";
      await this.queryList();
      this.visible = true;
    },
    async queryList() {
      this.loading = true;
      let res = await queryExtendFieldServiceList({
        page: 1,
        pageSize: 100,
      });
      if (res.status == 200) {
        this.expandFields = res.data.rows;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let params = Object.assign(
            {},
            pick(
              values,
              "contactEmail",
              "password",
              "name",
              "contactPerson",
              "contactMobile"
            ),
            { systemName: "中移税务风控", icon: "default", logo: "default" }
          );
          let res;
          if (this.isU8) {
            res = await registeru8(params);
          } else if (this.isU8c) {
            params.actCode = values.cdk;
            res = await registeru8c(params);
          } else {
            res = await registerSystemTenantid(params);
          }
          if (res.status == 200) {
            this.handleRela(res.data, values);
          } else {
            let msg = res.msg || res.message;
            let len = msg.length;
            this.$notification.error({
              message: "系统提示",
              description: len > 100 ? "系统错误，请联系管理员" : msg,
              duration: 4,
            });
          }
        }
      });
    },
    async handleRela(tenantId, values) {
      let params = {
        vos: [
          {
            expandFieldId: this.expandFields.filter(
              (item) => item.expandFieldName == "indexStandardType"
            )[0].id,
            expandFieldName: "indexStandardType",
            expandFieldValue: values.indexStandardType,
            tenantId,
          },
          {
            expandFieldId: this.expandFields.filter(
              (item) => item.expandFieldName == "taxpayerNature"
            )[0].id,
            expandFieldName: "taxpayerNature",
            expandFieldValue: values.taxpayerNature,
            tenantId,
          },
          {
            expandFieldId: this.expandFields.filter(
              (item) => item.expandFieldName == "companyName"
            )[0].id,
            expandFieldName: "companyName",
            expandFieldValue: values.name,
            tenantId,
          },
          {
            expandFieldId: this.expandFields.filter(
              (item) => item.expandFieldName == "taxpayerNo"
            )[0].id,
            expandFieldName: "taxpayerNo",
            expandFieldValue: values.taxpayerNo,
            tenantId,
          },
          {
            expandFieldId: this.expandFields.filter(
              (item) => item.expandFieldName == "registrationType"
            )[0].id,
            expandFieldName: "registrationType",
            expandFieldValue: values.registrationType,
            tenantId,
          },
          {
            expandFieldId: this.expandFields.filter(
              (item) => item.expandFieldName == "industry"
            )[0].id,
            expandFieldName: "industry",
            expandFieldValue: values.industry,
            tenantId,
          },
        ],
      };
      let accountType = getQueryString("accountType");
      if (accountType) {
        params.vos.push({
          expandFieldId: 14,
          expandFieldName: "accountType",
          expandFieldValue: 1,
          tenantId,
        });
      }
      if (this.isU8c) {
        params.vos.push({
          expandFieldId: 14,
          expandFieldName: "accountType",
          expandFieldValue: 3,
          tenantId,
        });
      }
      let res = await createSystemTenantidExtendBatch(params);
      if (res.status == 200) {
        this.$notification.success({
          message: "系统提示",
          description: "注册成功，请使用账号密码登录！",
          duration: 4,
        });
        this.handleCancel();
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    handleCancel() {
      this.state = {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: "#FF0000",
      };
      this.form.resetFields();
      this.visible = false;
    },
    openChange(status) {
      if (status) {
        this.open = true;
      } else {
        this.open = false;
      }
    },
    panelChange(value) {
      this.form.setFieldsValue({ since: String(value.year()) });
      this.open = false;
    },
    handleAccount(rule, value, callback) {
      if (value == "") {
        callback(new Error("账号不能为空"));
      } else {
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          callback(new Error("请输入正确格式的邮箱!"));
        } else {
          callback();
        }
      }
    },
    handlePasswordLevel(rule, value, callback) {
      if (value == "") {
        callback(new Error("密码不能为空"));
      }
      let level = 0;
      let reg =
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/;
      if (!reg.test(value)) {
        callback(new Error("密码由8位数字、大小写字母和特殊符号组成!"));
      }
      // 判断这个字符串中有没有数字
      if (/[0-9]/.test(value)) {
        level++;
      }
      // 判断字符串中有没有字母
      if (/[a-zA-Z]/.test(value)) {
        level++;
      }
      // 判断字符串中有没有特殊符号
      if (/[^0-9a-zA-Z_]/.test(value)) {
        level++;
      }
      this.state.passwordLevel = level;
      this.state.percent = level * 30;
      if (level >= 2) {
        if (level >= 3) {
          this.state.percent = 100;
        }
        callback();
      } else {
        if (level === 0) {
          this.state.percent = 10;
        }
        callback(new Error("密码强度不够"));
      }
    },

    handlePasswordCheck(rule, value, callback) {
      let password = this.form.getFieldValue("password");
      if (value === undefined) {
        callback(new Error("请输入密码"));
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error("两次密码不一致"));
      }
      callback();
    },

    handlePasswordInputClick(flag) {
      this.state.passwordLevelChecked = flag;
    },
  },
  computed: {
    passwordLevelClass() {
      return levelClass[this.state.passwordLevel];
    },
    passwordLevelName() {
      return levelNames[this.state.passwordLevel];
    },
    passwordLevelColor() {
      return levelColor[this.state.passwordLevel];
    },
  },
};
</script>
<style lang="less" scoped>
</style>
